import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { components } from 'react-select'
import _ from 'underscore';
import { binaryClosestIdx } from '../../../helpers'
import { Repository as APIRepository, FetchPost } from '../../api'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { getAccountSessionData } from '../../../pages/Account/Common'
import { selectAllDataRecordingTimePeriodTypes } from '../../AppInfo'


const processBladeFromAPI = (state, blade) => {
  //verticalRackGroup.lastStatusUpdatedOn = new Date().getTime();

  blade.loadedFromDatabase = true;

  blade.lastStatusUpdatedOn = new Date().getTime();
  blade.lastLiveDataUpdatedOn = 0;

  if (blade.liveData === undefined) {
    blade.liveData = {}
  }

  if (blade.analyticsData === undefined) {
    blade.analyticsData = {}
  }

  if (blade.initializedDataRecordingTimePeriodTypes === undefined) {
    blade.initializedDataRecordingTimePeriodTypes = false
  }



  if (blade.loading_status_data === undefined) {
    blade.loading_status_data = "idle"
  }
  if (blade.loading_live_data === undefined) {
    blade.loading_live_data = "idle"
  }
  if (blade.loading_analytics_data === undefined) {
    blade.loading_analytics_data = "idle"
  }
  if (blade.runtime_information === undefined) {
    blade.runtime_information = {}
  }



  return blade
}

const processBladeGroupFromAPI = (state, bladeGroup) => {
  return bladeGroup
}


const processBladeZoneFromAPI = (state, zone) => {
  zone.lastStatusUpdatedOn = new Date().getTime();
  if (zone.components === undefined) {
    zone.components = []
  }

  if (zone.loading_status_data === undefined) {
    zone.loading_status_data = "idle"
  }

  zone.events = [
    {
      id: 1,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
    {
      id: 2,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
    {
      id: 3,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
  ]

  return zone
}


/*const processNurseryZoneFromAPI = (growOutZone) =>  {
  growOutZone.lastStatusUpdatedOn = new Date().getTime();
  growOutZone.components = []

  growOutZone.events = [
    {
      id: 1,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
    {
      id: 2,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
    {
      id: 3,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
  ]


  return growOutZone
}
const processStandardGrowOutZoneFromAPI = (growOutZone) =>  {
  growOutZone.lastStatusUpdatedOn = new Date().getTime();
  growOutZone.components = []

  growOutZone.events = [
    {
      id: 1,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
    {
      id: 2,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
    {
      id: 3,
      occurred_on: new Date().getTime(),
      type: "component_failure",
      type_display_name: "Pump Failure",
      priority: "critical",
      content: "Aeroponics Issues",
    },
  ]

  return growOutZone
}*/





const uniqueConfigurationMapKeys = {
  "AuxALiquidSystemInstalled": "auxalsi",
  "AuxBLiquidSystemInstalled": "auxblsi",
}
export const getUniqueConfigurationByName = (verticalRack, name) => {
  if (verticalRack.unique_configuration === undefined) {
    return undefined;
  }
  if (uniqueConfigurationMapKeys[name] === undefined) {
    return undefined;
  }
  return verticalRack.unique_configuration.properties[uniqueConfigurationMapKeys[name]];
}


export const getLiveDataItem = (verticalRack, componentMap, componentName, identifiers) => {
  let components = componentMap.components.filter((c) => c.name === componentName)
  if (components.length <= 1) {
    let identifierInfos = []
    let dataItems = []
    for (let identifier of identifiers) {
      let identifierInfo = components[0] !== undefined ? (components[0].data_types ? components[0].data_types.find((dT) => dT.identifier === identifier) : undefined) : undefined
      identifierInfos.push(identifierInfo)
      dataItems.push(identifierInfo !== undefined && verticalRack.liveData[components[0].id] !== undefined && verticalRack.liveData[components[0].id][identifierInfo.identifier] !== undefined ? verticalRack.liveData[components[0].id][identifierInfo.identifier] : null)
    }
    return [components[0], identifierInfos, dataItems]
  } else {
    let componentPackages = []
    for (let component of components) {
      let identifierInfos = []
      let dataItems = []
      for (let identifier of identifiers) {
        let identifierInfo = components[0] !== undefined ? (components[0].data_types ? components[0].data_types.find((dT) => dT.identifier === identifier) : undefined) : undefined
        identifierInfos.push(identifierInfo)
        dataItems.push(identifierInfo !== undefined && verticalRack.liveData[components[0].id] !== undefined && verticalRack.liveData[components[0].id][identifierInfo.identifier] !== undefined ? verticalRack.liveData[components[0].id][identifierInfo.identifier] : null)
      }
      componentPackages.push([component, identifierInfos, dataItems])
    }
    return componentPackages
  }
}




export const getBladeById = createAsyncThunk('blade/getBladeById', async ({ bladeId, bladeIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeId !== undefined) {
    payload.blade_id = bladeId
  }
  if (bladeIds !== undefined) {
    payload.blade_ids = bladeIds
  }
  return await FetchPost(APIRepository.Blade.GetBladeById, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingBladeStatus === 'pending') {
        return false
      }
    },
  })


export const getBladeByServiceId = createAsyncThunk('blade/getBladeByServiceId', async ({ bladeServiceId, bladeServiceIds, bladeServiceUIDs }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeServiceId !== undefined) {
    payload.service_id = bladeServiceId
  }
  if (bladeServiceIds !== undefined) {
    payload.service_ids = bladeServiceIds
  }
  return await FetchPost(APIRepository.Blade.GetBladeByServiceId, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingBladeStatus === 'pending') {
        return false
      }
    },
  })


export const getBladeBySerialNumber = createAsyncThunk('blade/getBladeBySerialNumber', async ({ bladeSerialNumber, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_serial_number: bladeSerialNumber
  }
  return await FetchPost(APIRepository.Blade.GetBladeBySerialNumber, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingBladeStatus === 'pending') {
        return false
      }
    },
  })


export const getBladeByUID = createAsyncThunk('blade/getBladeByUID', async ({ bladeUID, bladeUIDs, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeUID !== undefined) {
    payload.blade_uid = bladeUID
  }
  if (bladeUIDs !== undefined) {
    payload.blade_uids = bladeUIDs
  }
  return await FetchPost(APIRepository.Blade.GetBladeByUID, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingBladeStatus === 'pending') {
        return false
      }
    },
  })



export const getAllBladeGroupsByFacilityId = createAsyncThunk('blade/getAllBladeGroupsByFacilityId', async ({ facilityId, facilityIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (facilityId !== undefined) {
    payload.facility_id = facilityId
  }
  if (facilityIds !== undefined) {
    payload.facility_ids = facilityIds
  }
  return await FetchPost(APIRepository.Blade.GetAllBladeGroupsByFacilityId, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingGroupsStatus === 'pending') {
        return false
      }
    },
  })


export const getBladeGroupById = createAsyncThunk('blade/getBladeGroupById', async ({ bladeGroupId, bladeGroupIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeGroupId !== undefined) {
    payload.id = bladeGroupId
  }
  if (bladeGroupIds !== undefined) {
    payload.ids = bladeGroupIds
  }
  return await FetchPost(APIRepository.Blade.GetBladeGroupById, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingGroupsStatus === 'pending') {
        return false
      }
    },
  })


export const getBladeZoneByUID = createAsyncThunk('blade/getBladeZoneByUID', async ({ UID, UIDs }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (UID !== undefined) {
    payload.uid = UID
  }
  if (UIDs !== undefined) {
    payload.uids = UIDs
  }
  return await FetchPost(APIRepository.Blade.GetBladeZoneByUID, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingBladeZoneStatus === 'pending') {
        return false
      }
    },
  })




export const getBladeStatusById = createAsyncThunk('blade/getBladeStatusById', async ({ bladeId, bladeIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeId !== undefined) {
    payload.blade_id = bladeId
  }
  if (bladeIds !== undefined) {
    payload.blade_ids = bladeIds
  }
  return await FetchPost(APIRepository.Blade.GetBladeStatusById, payload)
})


export const getBladeZoneStatusByUID = createAsyncThunk('blade/getBladeZoneStatusByUID', async ({ bladeZoneUID, bladeZoneUIDs }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeZoneUID !== undefined) {
    payload.blade_zone_uid = bladeZoneUID
  }
  if (bladeZoneUIDs !== undefined) {
    payload.blade_zone_uids = bladeZoneUIDs
  }
  return await FetchPost(APIRepository.Blade.GetBladeZoneStatusByUID, payload)
})


export const setBladeRuntimeProperty = createAsyncThunk('blade/setBladeRuntimeProperty', async ({ bladeId, properties, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_id: bladeId,
    properties: properties
  }
  return await FetchPost(APIRepository.Blade.SetBladeRuntimeProperty, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.settingBladeRuntimeProperty === 'pending') {
        return false
      }
    },
  })


export const setBladeZoneRuntimeProperty = createAsyncThunk('blade/setBladeZoneRuntimeProperty', async ({ bladeZoneUID, properties, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_zone_uid: bladeZoneUID,
    properties: properties
  }
  return await FetchPost(APIRepository.Blade.SetBladeZoneRuntimeProperty, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.settingBladeZoneRuntimeProperty === 'pending') {
        return false
      }
    },
  })




export const markActiveIssueAsManuallyResolved = createAsyncThunk('blade/markActiveIssueAsManuallyResolved', async ({ bladeId, issueId, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_id: bladeId,
    issue_id: issueId
  }
  return await FetchPost(APIRepository.Blade.MarkActiveIssueAsManuallyResolved, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.markingActiveIssueAsResolved === 'pending') {
        return false
      }
    },
  })


export const getBladeLiveDataById = createAsyncThunk('blade/getBladeLiveDataById', async ({ bladeId, bladeIds }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
  }
  if (bladeId !== undefined) {
    payload.blade_id = bladeId
  }
  if (bladeIds !== undefined) {
    payload.blade_ids = bladeIds
  }


  return await FetchPost(APIRepository.Blade.GetBladeLiveDataById, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingRackLiveData === 'pending') {
        return false
      }
    },
  })


export const getBladeAnalyticsData = createAsyncThunk('blade/getBladeAnalyticsData', async ({ entries }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    entries: entries //map[int]map[string]map[int][]int -- time period type id : map of start/end date string to map of rack ids to list of component ids
  }
  return await FetchPost(APIRepository.Blade.GetBladeAnalyticsData, payload)
})



export const getBladeConfigurationMap = createAsyncThunk('blade/getBladeConfigurationMap', async ({ maps }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    maps: maps //Must be []configurationId:{mapKey:*version
  }
  return await FetchPost(APIRepository.Blade.GetBladeConfigurationMap, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingConfigurationMaps === 'pending') {
        return false
      }
    },
  })



export const getBladeZoneConfigurationMap = createAsyncThunk('blade/getBladeZoneConfigurationMap', async ({ maps }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    maps: maps //Must be []configurationId:{mapKey:*version
  }
  return await FetchPost(APIRepository.Blade.GetBladeZoneConfigurationMap, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.loadingZoneConfigurationMapsStatus === 'pending') {
        return false
      }
    },
  })




export const validateBladeSystemConnection = createAsyncThunk('blade/validateVerticalRackSystemConnection', async ({ bladeUID, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_uid: bladeUID,
  }

  return await FetchPost(APIRepository.Blade.ValidateBladeSystemConnection, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.validatingBladeConnectedToSystem === 'pending') {
        return false
      }
    },
  })


export const createNewBlade = createAsyncThunk('blade/createNewBlade', async ({ facilityId, bladeGroupId, bladeInfo, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    blade_group_id: bladeGroupId,
    blade_info: bladeInfo,
  }

  return await FetchPost(APIRepository.Blade.CreateNewBlade, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.creatingNewBlade === 'pending') {
        return false
      }
    },
  })

export const createNewBladeGroup = createAsyncThunk('blade/createNewBladeGroup', async ({ facilityId, groupName, uid, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    group_name: groupName,
    uid: uid
  }

  return await FetchPost(APIRepository.Blade.CreateNewBladeGroup, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.creatingNewBladeGroup === 'pending') {
        return false
      }
    },
  })


export const moveBladeToGroup = createAsyncThunk('blade/moveBladeToGroup', async ({ bladeUID, bladeGroupId }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_uid: bladeUID,
    blade_group_id: bladeGroupId,
  }
  return await FetchPost(APIRepository.Blade.MoveBladeToGroup, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.movingBladeToGroup === 'pending') {
        return false
      }
    },
  })



export const linkBladeToControlDevice = createAsyncThunk('blade/linkBladeToControlDevice', async ({ bladeUID, controlDeviceSerialNumber, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_uid: bladeUID,
    control_device_serial_number: controlDeviceSerialNumber,
  }

  return await FetchPost(APIRepository.Blade.LinkBladeToControlDevice, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.linkingBladeToControlDevice === 'pending') {
        return false
      }
    },
  })

export const addBladeToFarm = createAsyncThunk('blade/addBladeToFarm', async ({ facilityId, bladeUID, bladeName, bladeIndex, bladeGroupId, bladeSides, zoneChanges, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    facility_id: facilityId,
    blade_uid: bladeUID,
    blade_name: bladeName,
    blade_index: bladeIndex,
    blade_group_id: bladeGroupId,
    blade_sides: bladeSides,
    zone_changes: zoneChanges
  }

  console.log(payload)

  return await FetchPost(APIRepository.Blade.AddBladeToFarm, payload)
},
  {
    condition: (args, { getState }) => {
      const { blade } = getState()
      if (blade.addingBladeToFarm === 'pending') {
        return false
      }
    },
  })






export const markGrowZoneForCleanse = createAsyncThunk('blade/markGrowZoneForCleanse', async ({ bladeId, zoneUID, forReservoir, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    blade_id: bladeId,
    zone_uid: zoneUID,
    for_reservoir: forReservoir,
  }

  return await FetchPost(APIRepository.Blade.MarkGrowZoneForCleanse, payload)
})



export const getBladeCleanseSubtask = createAsyncThunk('blade/getBladeCleanseSubtask', async ({ taskId, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    task_id: taskId
  }

  return await FetchPost(APIRepository.Blade.GetBladeCleanseSubtask, payload)
})


export const setBladeCleanseSubtaskStage = createAsyncThunk('blade/setBladeCleanseSubtaskStage', async ({ taskId, stage, callback }, { getState }) => {
  let payload = {
    ...getAccountSessionData(getState()),
    task_id: taskId,
    stage: stage
  }

  return await FetchPost(APIRepository.Blade.SetBladeCleanseSubtaskStage, payload)
})





export const bladesSlice = createSlice({
  name: 'blades',
  initialState: {
    blade: [],
    loadedBladeServiceIds: [],
    loadedBladeUIDs: [],

    groups: [],

    zones: [],
    loadedZoneUIDs: [],

    loadedInitialBladeGroups: [],


    tempVerticalRackGroupId: 1,
    tempVerticalRackId: 1,
    configurationMaps: [],
    zoneConfigurationMaps: [],
    status: 'idle',
    error: null,
    haveInitialData: false,
    loadingData: false,
    loadingBladeStatus: 'idle',
    loadingGroupsStatus: 'idle',
    loadingBladeStatusInfo: 'idle',
    settingBladeRuntimeProperty: 'idle',
    settingBladeZoneRuntimeProperty: 'idle',
    markingActiveIssueAsResolved: 'idle',
    loadingRackLiveData: 'idle',
    loadingBladeAnalyticsData: 'idle',
    loadingConfigurationMaps: 'idle',
    loadingZoneConfigurationMapsStatus: 'idle',

    creatingNewBlade: 'idle',
    creatingNewBladeGroup: 'idle',
    movingBladeToGroup: 'idle',
    linkingBladeToControlDevice: 'idle',
    addingBladeToFarm: 'idle',
    validatingBladeConnectedToSystem: 'idle',
  },
  reducers: {
    initializeComponentForAnalyticsData: (state, action) => { //Action includes rack group id, zone id, rack id, component id
      let updates = {
        ...state,

        blade: state.blade.map((blade, index) => {
          if (blade.id !== action.payload.bladeId) {
            return blade
          }



          let dataTimePeriods = { ...blade.analyticsData, [action.payload.rackComponentId]: {} }
          for (let dataRecordingTimePeriodType of action.payload.dataRecordingTimePeriodTypes) {
            dataTimePeriods[action.payload.rackComponentId][dataRecordingTimePeriodType.id] = {
              loadingStatus: "idle",
              data: {},
              dataChunks: {},
              haveNewData: {},
              haveDataUpUntil: 0,
              changedVersion: 0,

              energyData: {},
              energyDataChunks: {},
              haveNewEnergyData: {},
              haveEnergyDataUpUntil: 0,
              changedEnergyVersion: 0


            }
          }

          return {
            ...blade,
            analyticsData: dataTimePeriods,
            initializedDataRecordingTimePeriodTypes: true
          }

        }),

        zones: state.zones.map((zone) => {
          if (zone.id !== action.payload.zoneId) {
            return zone
          }
          //console.log(growOutZone.components)
          let exists = false
          let newZoneComponentPayload = { id: action.payload.zoneComponentId, componentInfo: action.payload.componentInfo, zoneType: action.payload.zoneType, bladeId: action.payload.bladeId }
          let zoneComponents = zone.components.map((zoneComponent) => {
            if (zoneComponent.id !== action.payload.zoneComponentId) {
              return zoneComponent
            }
            exists = true
            return newZoneComponentPayload
          })

          if (!exists) {
            zoneComponents.push(newZoneComponentPayload)
          }

          return {
            ...zone,
            components: [...zoneComponents]
          }
        })

      }

      return updates

    }, assignActiveRecipeToZone: (state, action) => { //Action includes rack group id, zone id, rack id, component id
      return {
        ...state, zones: state.zones.map((zone) => {
          if (zone.id !== action.payload.zone_id) {
            return zone
          }
          return { ...zone, active_zone_recipe: action.payload.active_zone_recipe }
        })
      }
    }, disassignActiveRecipeForZone: (state, action) => { //Action includes rack group id, zone id, rack id, component id
      return {
        ...state, zones: state.zones.map((zone) => {
          if (zone.id !== action.payload.zone_id) {
            return zone
          }
          return { ...zone, active_zone_recipe: null }
        })
      }
    }

  },
  extraReducers: {

    [getBladeById.pending]: (state) => {
      state.loadingBladeStatus = 'pending';
    },

    [getBladeById.fulfilled]: (state, action) => {
      state.loadingBladeStatus = 'fulfilled';
      if (action.payload.blades !== null) {
        for (let blade of action.payload.blades) {
          if (blade) {
            let exists = false
            for (let bladeIndex in state.blade) {
              if (state.blade[bladeIndex].id === blade.id) {
                //Get the UID

                state.blade[bladeIndex] = processBladeFromAPI(state, { ...state.blade[bladeIndex], ...blade })
                exists = true
                break
              }
            }
            if (!exists) {
              state.blade.push(processBladeFromAPI(state, blade))
              state.loadedBladeServiceIds.push(blade.service_id)
              state.loadedBladeUIDs.push(blade.uid)
            }
          }
        }
      }
    },

    [getBladeById.rejected]: (state) => {
      state.loadingBladeStatus = 'rejected';
    },


    [getBladeByServiceId.pending]: (state) => {
      state.loadingBladeStatus = 'pending';
    },

    [getBladeByServiceId.fulfilled]: (state, action) => {
      state.loadingBladeStatus = 'fulfilled';
      if (action.payload.blades !== null) {
        for (let blade of action.payload.blades) {
          if (blade) {
            let exists = false
            for (let bladeIndex in state.blade) {
              if (state.blade[bladeIndex].id === blade.id) {
                //Get the UID

                state.blade[bladeIndex] = processBladeFromAPI(state, { ...state.blade[bladeIndex], ...blade })
                exists = true
                break
              }
            }
            if (!exists) {
              state.blade.push(processBladeFromAPI(state, blade))
              state.loadedBladeServiceIds.push(blade.service_id)
              state.loadedBladeUIDs.push(blade.uid)
            }
          }
        }
      }
    },

    [getBladeByServiceId.rejected]: (state) => {
      state.loadingBladeStatus = 'rejected';
    },




    [getBladeBySerialNumber.pending]: (state) => {
      state.loadingBladeStatus = 'pending';
    },

    [getBladeBySerialNumber.fulfilled]: (state, action) => {
      state.loadingBladeStatus = 'fulfilled';
      if (action.payload.blade !== null) {
        let exists = false
        let updatedBlade
        for (let bladeIndex in state.blade) {
          if (state.blade[bladeIndex].id === action.payload.blade.id) {
            //Get the UID
            updatedBlade = processBladeFromAPI(state, { ...state.blade[bladeIndex], ...action.payload.blade })
            state.blade[bladeIndex] = updatedBlade
            exists = true
            break
          }
        }
        if (!exists) {
          updatedBlade = processBladeFromAPI(state, action.payload.blade)
          state.blade.push(updatedBlade)
          state.loadedBladeServiceIds.push(updatedBlade.service_id)
          state.loadedBladeUIDs.push(updatedBlade.uid)
        }

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true, updatedBlade)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [getBladeBySerialNumber.rejected]: (state, action) => {
      state.loadingBladeStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [getBladeByUID.pending]: (state) => {
      state.loadingBladeStatus = 'pending';
    },

    [getBladeByUID.fulfilled]: (state, action) => {
      state.loadingBladeStatus = 'fulfilled';
      if (action.payload.blades !== null) {
        for (let blade of action.payload.blades) {
          if (blade) {
            let exists = false
            for (let bladeIndex in state.blade) {
              if (state.blade[bladeIndex].id === blade.id) {
                //Get the UID

                state.blade[bladeIndex] = processBladeFromAPI(state, { ...state.blade[bladeIndex], ...blade })
                exists = true
                break
              }
            }
            if (!exists) {
              state.blade.push(processBladeFromAPI(state, blade))
              state.loadedBladeServiceIds.push(blade.service_id)
              state.loadedBladeUIDs.push(blade.uid)
            }
          }
        }

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [getBladeByUID.rejected]: (state, action) => {
      state.loadingBladeStatus = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    [getAllBladeGroupsByFacilityId.pending]: (state) => {
      state.loadingGroupsStatus = 'pending';
    },

    [getAllBladeGroupsByFacilityId.fulfilled]: (state, action) => {
      state.loadingGroupsStatus = 'fulfilled';
      if (action.meta.arg.facilityId !== undefined) {
        state.loadedInitialBladeGroups.push(action.meta.arg.facilityId)
      }
      if (action.meta.arg.facilityIds !== undefined) {
        state.loadedInitialBladeGroups.push(...action.meta.arg.facilityIds)
      }

      console.log(action.payload)
      if (action.payload.blade_groups !== null) {
        for (let bladeGroup of action.payload.blade_groups) {
          if (bladeGroup) {
            let exists = false
            for (let groupIndex in state.groups) {
              if (state.groups[groupIndex].id === bladeGroup.id) {
                //Get the UID

                state.groups[groupIndex] = processBladeGroupFromAPI(state, { ...state.groups[groupIndex], ...bladeGroup })
                exists = true
                break
              }
            }
            if (!exists) {
              state.groups.push(processBladeGroupFromAPI(state, bladeGroup))
            }
          }
        }
      }
    },

    [getAllBladeGroupsByFacilityId.rejected]: (state) => {
      state.loadingGroupsStatus = 'rejected';
    },



    [getBladeGroupById.pending]: (state) => {
      state.loadingGroupsStatus = 'pending';
    },

    [getBladeGroupById.fulfilled]: (state, action) => {
      state.loadingGroupsStatus = 'fulfilled';
      if (action.payload.blade_groups !== null) {
        for (let bladeGroup of action.payload.blade_groups) {
          if (bladeGroup) {
            let exists = false
            for (let groupIndex in state.groups) {
              if (state.groups[groupIndex].id === bladeGroup.id) {
                //Get the UID

                state.groups[groupIndex] = processBladeGroupFromAPI(state, { ...state.groups[groupIndex], ...bladeGroup })
                exists = true
                break
              }
            }
            if (!exists) {
              state.groups.push(processBladeGroupFromAPI(state, bladeGroup))
            }
          }
        }
      }
    },

    [getBladeGroupById.rejected]: (state) => {
      state.loadingGroupsStatus = 'rejected';
    },


    [getBladeZoneByUID.pending]: (state) => {
      state.loadingBladeZoneStatus = 'pending';
    },

    [getBladeZoneByUID.fulfilled]: (state, action) => {
      state.loadingBladeZoneStatus = 'fulfilled';
      if (action.payload.blade_zones !== null) {
        for (let zone of action.payload.blade_zones) {
          if (zone) {
            let exists = false
            for (let zoneIndex in state.zones) {
              if (state.zones[zoneIndex].uid === zone.uid) {
                //Get the UID

                state.zones[zoneIndex] = processBladeZoneFromAPI(state, { ...state.zones[zoneIndex], ...zone })
                exists = true
                break
              }
            }
            if (!exists) {
              state.zones.push(processBladeZoneFromAPI(state, zone))
              state.loadedZoneUIDs.push(zone.uid)
            }
          }
        }
      }
    },

    [getBladeZoneByUID.rejected]: (state) => {
      state.loadingBladeZoneStatus = 'rejected';
    },




    [getBladeStatusById.pending]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.bladeId !== undefined && blade.id === action.meta.arg.bladeId) {
          return { ...blade, loading_status_data: "pending" }
        }
        if (action.meta.arg.bladeIds !== undefined) {
          for (let bladeId of action.meta.arg.bladeIds) {
            if (blade.id === bladeId) {
              return { ...blade, loading_status_data: "pending" }
            }
          }
        }
        return blade
      })
    },

    [getBladeStatusById.fulfilled]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.bladeId !== undefined && blade.id === action.meta.arg.bladeId) {
          return { ...blade, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime() }
        }
        if (action.meta.arg.bladeIds !== undefined) {
          for (let bladeId of action.meta.arg.bladeIds) {
            if (blade.id === bladeId) {
              return { ...blade, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return blade
      })

      if (action.payload.blade_status_data !== null) {

        for (let [bladeIdAsString, bladeStatusPayload] of Object.entries(action.payload.blade_status_data)) {
          const bladeId = parseInt(bladeIdAsString)
          state.blade = state.blade.map((blade) => {
            if (blade.id !== bladeId)
              return blade
            return { ...blade, ...bladeStatusPayload }
          })
        }
      }
    },

    [getBladeStatusById.rejected]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.bladeId !== undefined && blade.id === action.meta.arg.bladeId) {
          return { ...blade, loading_status_data: "rejected" }
        }
        if (action.meta.arg.bladeIds !== undefined) {
          for (let bladeId of action.meta.arg.bladeIds) {
            if (blade.id === bladeId) {
              return { ...blade, loading_status_data: "rejected" }
            }
          }
        }
        return blade
      })
    },


    [getBladeZoneStatusByUID.pending]: (state, action) => {
      state.zones = state.zones.map((zone) => {
        if (action.meta.arg.bladeZoneUID !== undefined && zone.uid === action.meta.arg.bladeZoneUID) {
          return { ...zone, loading_status_data: "pending" }
        }
        if (action.meta.arg.bladeZoneUIDs !== undefined) {
          for (let zoneUID of action.meta.arg.bladeZoneUIDs) {
            if (zone.uid === zoneUID) {
              return { ...zone, loading_status_data: "pending" }
            }
          }
        }
        return zone
      })
    },

    [getBladeZoneStatusByUID.fulfilled]: (state, action) => {
      state.zones = state.zones.map((zone) => {
        if (action.meta.arg.bladeZoneUID !== undefined && zone.uid === action.meta.arg.bladeZoneUID) {
          return { ...zone, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime() }
        }
        if (action.meta.arg.bladeZoneUIDs !== undefined) {
          for (let zoneUID of action.meta.arg.bladeZoneUIDs) {
            if (zone.uid === zoneUID) {
              return { ...zone, loading_status_data: "fulfilled", last_status_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return zone
      })

      if (action.payload.zone_status !== null) {
        state.zones = state.zones.map((zone) => {
          if (action.payload.zone_status[zone.uid] === undefined) {
            return zone
          }
          return processBladeZoneFromAPI(state, { ...zone, ...action.payload.zone_status[zone.uid] })
        })
      }
    },

    [getBladeZoneStatusByUID.rejected]: (state, action) => {
      state.zones = state.zones.map((zone) => {
        if (action.meta.arg.bladeZoneUID !== undefined && zone.uid === action.meta.arg.bladeZoneUID) {
          return { ...zone, loading_status_data: "rejected" }
        }
        if (action.meta.arg.bladeZoneUIDs !== undefined) {
          for (let zoneUID of action.meta.arg.bladeZoneUIDs) {
            if (zone.uid === zoneUID) {
              return { ...zone, loading_status_data: "rejected" }
            }
          }
        }
        return zone
      })
    },


    [setBladeRuntimeProperty.pending]: (state) => {
      state.settingBladeRuntimeProperty = 'pending';
    },

    [setBladeRuntimeProperty.fulfilled]: (state, action) => {
      state.settingBladeRuntimeProperty = 'fulfilled';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(true)
      }
    },

    [setBladeRuntimeProperty.rejected]: (state, action) => {
      state.settingBladeRuntimeProperty = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [setBladeZoneRuntimeProperty.pending]: (state) => {
      state.settingBladeZoneRuntimeProperty = 'pending';
    },

    [setBladeZoneRuntimeProperty.fulfilled]: (state, action) => {
      state.settingBladeZoneRuntimeProperty = 'fulfilled';
      state.zones = state.zones.map((zone) => {
        if (zone.uid !== action.meta.arg.bladeZoneUID) {
          return zone
        }
        return { ...zone, runtime_information: { ...zone.runtime_information, ...action.meta.arg.properties } }
      })
      if (action.meta.arg.callback) {
        action.meta.arg.callback(true)
      }
    },

    [setBladeZoneRuntimeProperty.rejected]: (state, action) => {
      state.settingBladeZoneRuntimeProperty = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [markActiveIssueAsManuallyResolved.pending]: (state) => {
      state.markingActiveIssueAsResolved = 'pending';
    },

    [markActiveIssueAsManuallyResolved.fulfilled]: (state, action) => {
      state.markingActiveIssueAsResolved = 'fulfilled';
      
      if (action.meta.arg.callback) {
        action.meta.arg.callback(true)
      }
    },

    [markActiveIssueAsManuallyResolved.rejected]: (state, action) => {
      state.markingActiveIssueAsResolved = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },


    [getBladeLiveDataById.pending]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.bladeId !== undefined && blade.id === action.meta.arg.bladeId) {
          return { ...blade, loading_live_data: "pending" }
        }
        if (action.meta.arg.bladeIds !== undefined) {
          for (let bladeId of action.meta.arg.bladeIds) {
            if (blade.id === bladeId) {
              return { ...blade, loading_live_data: "pending" }
            }
          }
        }
        return blade
      })
    },

    [getBladeLiveDataById.fulfilled]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.bladeId !== undefined && blade.id === action.meta.arg.bladeId) {
          return { ...blade, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
        }
        if (action.meta.arg.bladeIds !== undefined) {
          for (let bladeId of action.meta.arg.bladeIds) {
            if (blade.id === bladeId) {
              return { ...blade, loading_live_data: "fulfilled", last_live_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return blade
      })

      if (action.payload.live_data !== null) {
        for (let [bladeId, liveData] of Object.entries(action.payload.live_data)) {
          for (let blade of state.blade) {
            if (blade.id === parseInt(bladeId)) {
              blade.liveData = liveData
              break
            }
          }
        }
      }
    },

    [getBladeLiveDataById.rejected]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.bladeId !== undefined && blade.id === action.meta.arg.bladeId) {
          return { ...blade, loading_live_data: "rejected" }
        }
        if (action.meta.arg.bladeIds !== undefined) {
          for (let bladeId of action.meta.arg.bladeIds) {
            if (blade.id === bladeId) {
              return { ...blade, loading_live_data: "rejected" }
            }
          }
        }
        return blade
      })
    },



    [getBladeAnalyticsData.pending]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.entries !== undefined) {
          for (let bladeId of Object.keys(action.meta.arg.entries)) {
            if (blade.id === parseInt(bladeId)) {
              console.log("pending")
              return { ...blade, loading_analytics_data: "pending" }
            }
          }
        }
        return blade
      })
    },

    [getBladeAnalyticsData.fulfilled]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.entries !== undefined) {
          for (let bladeId of Object.keys(action.meta.arg.entries)) {
            if (blade.id === parseInt(bladeId)) {
              return { ...blade, loading_analytics_data: "fulfilled", last_analytics_data_loaded_on: new Date().getTime() }
            }
          }
        }
        return blade
      })
      if (action.payload.analytics_data !== null) {
        //console.log(action.payload.analytics_data)
        for (let componentDataChunk of action.payload.analytics_data) {
          state.blade = state.blade.map((blade) => {
            if (blade.id !== componentDataChunk.rack_id) {
              return blade
            }

            if (componentDataChunk["component_id"] in blade.analyticsData) {
              let componentDataTimePeriodsTypes = blade.analyticsData[componentDataChunk["component_id"]]
              const currentDataRecordingTimePeriodType = action.meta.arg.timePeriodTypes.find((dataRecordingTimePeriodType) => { return dataRecordingTimePeriodType.id === componentDataChunk["time_period_type_id"]; })
              if (currentDataRecordingTimePeriodType !== undefined && componentDataChunk["time_period_type_id"].toString() in componentDataTimePeriodsTypes) {
                let componentData = componentDataTimePeriodsTypes[componentDataChunk["time_period_type_id"].toString()]
                for (let dataChunk of componentDataChunk["data"]) {
                  const chunkStartedOn = parseInt(dataChunk["entry_index"]) * currentDataRecordingTimePeriodType.duration * 1000
                  var newData = false;

                  let currentDataChunk = componentData.dataChunks[parseInt(dataChunk["entry_index"])]
                  if (currentDataChunk === undefined) {
                    currentDataChunk = componentData.dataChunks[parseInt(dataChunk["entry_index"])] = dataChunk
                  }

                  //console.log(currentDataChunk)



                  if (dataChunk.completed) {


                    const dataItemList = dataChunk.values.split('\n')

                    const processedData = {}

                    //console.log(dataChunk.values)
                    for (let dataItemString of dataItemList) {
                      const dataItemInfo = dataItemString.split(',')
                      const identifier = dataItemInfo[0]
                      let forTime
                      if (currentDataRecordingTimePeriodType["max_number_of_points"] == 0) {
                        forTime = chunkStartedOn + parseInt(dataItemInfo[1])
                      } else {
                        forTime = chunkStartedOn + parseInt(dataItemInfo[1] * 1000)
                      }

                      const value = parseFloat(dataItemInfo[2])
                      const flag = dataItemInfo[3]

                      if (processedData[identifier] === undefined) {
                        processedData[identifier] = []
                      }
                      processedData[identifier].push({ x: forTime - action.meta.arg.dateOffset, y: value })

                      componentData.haveNewData[identifier] = true
                      newData = true;

                    }

                    for (let identifier in processedData) {
                      if (componentData.data[identifier] === undefined) {
                        componentData.data[identifier] = []
                      }
                      if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr") {
                        processedData[identifier].sort(function (a, b) {
                          if (a.x > b.x)
                            return 1;
                          if (a.x < b.x)
                            return -1;
                          return 0

                        });

                        let lastValue = null
                        for (let dataItem of processedData[identifier]) {
                          if (!lastValue && componentData.data[identifier].length > 0) {
                            let lastDataItem = binaryClosestIdx(componentData.data[identifier], dataItem.x - 1, 'x')
                            if (lastDataItem && componentData.data[identifier][lastDataItem] !== undefined) {
                              if (componentData.data[identifier][lastDataItem].x < dataItem.x) {
                                lastValue = componentData.data[identifier][lastDataItem].y
                              }
                            }
                          }
                          if (lastValue) {
                            componentData.data[identifier].push({ x: dataItem.x - 1 - action.meta.arg.dateOffset, y: lastValue })
                          }
                          componentData.data[identifier].push({ x: dataItem.x - action.meta.arg.dateOffset, y: dataItem.y })
                          lastValue = dataItem.y
                        }
                      } else {
                        componentData.data[identifier].push(...processedData[identifier])
                      }
                    }



                  } else {
                    //Live data chunk
                    for (let identifier in dataChunk["live_values"]) {
                      let isNew = false

                      if (componentData.data[identifier] === undefined) {
                        componentData.data[identifier] = []
                        isNew = true
                      }

                      let lastValue = null
                      for (let forTime in dataChunk["live_values"][identifier]) {
                        const pointTime = forTime
                        if (identifier === "lightr" || identifier === "lightg" || identifier === "lightb" || identifier === "lightfr") {
                          if (!lastValue && componentData.data[identifier].length > 0) {
                            let lastDataItem = binaryClosestIdx(componentData.data[identifier], pointTime - 1, 'x')
                            if (lastDataItem && componentData.data[identifier][lastDataItem] !== undefined) {
                              if (componentData.data[identifier][lastDataItem].x < pointTime) {
                                lastValue = componentData.data[identifier][lastDataItem].y
                              }
                            }
                          }
                          if (lastValue) {
                            componentData.data[identifier].push({ x: pointTime - 1 - action.meta.arg.dateOffset, y: lastValue })
                          }
                          componentData.data[identifier].push({ x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime] })
                        } else {
                          componentData.data[identifier].push({ x: pointTime - action.meta.arg.dateOffset, y: dataChunk["live_values"][identifier][forTime] })
                        }
                        newData = true;
                        componentData.haveNewData[identifier] = true

                        if (pointTime > componentData.haveDataUpUntil) {
                          componentData.haveDataUpUntil = parseInt(pointTime)
                        }

                        lastValue = dataChunk["live_values"][identifier][forTime]
                      }
                    }

                    currentDataChunk.to = componentData.haveDataUpUntil
                  }


                  if (newData) {
                    for (let identifier in componentData.data) {
                      componentData.data[identifier].sort(function (a, b) {
                        if (a.x > b.x)
                          return 1;
                        if (a.x < b.x)
                          return -1;
                        return 0

                      });
                    }

                    componentData.changedVersion += 1
                  }

                }
              }

            }

            return {
              ...blade,
              //analyticsData: updatedAnalyticsData
            }
          })
        }
      }
    },

    [getBladeAnalyticsData.rejected]: (state, action) => {
      state.blade = state.blade.map((blade) => {
        if (action.meta.arg.entries !== undefined) {
          for (let bladeId of Object.keys(action.meta.arg.entries)) {
            if (blade.id === parseInt(bladeId)) {
              return { ...blade, loading_analytics_data: "rejected" }
            }
          }
        }
        return blade
      })
    },





    [getBladeConfigurationMap.pending]: (state) => {
      state.loadingConfigurationMaps = 'pending';
    },

    [getBladeConfigurationMap.fulfilled]: (state, action) => {
      state.loadingConfigurationMaps = 'fulfilled';
      if (action.payload.maps !== null) {
        for (let [configurationId, maps] of Object.entries(action.payload.maps)) {
          let foundConfigurationMap = state.configurationMaps.find((cM) => cM.id === parseInt(configurationId))
          if (foundConfigurationMap === undefined) {
            foundConfigurationMap = { id: parseInt(configurationId), component_map: {}, io_map: {} }
            state.configurationMaps.push(foundConfigurationMap)
          }
          for (let [mapKey, map] of Object.entries(maps)) {
            foundConfigurationMap[mapKey] = map
          }
        }
      }
    },

    [getBladeConfigurationMap.rejected]: (state) => {
      state.loadingConfigurationMaps = 'rejected';
    },



    [getBladeZoneConfigurationMap.pending]: (state) => {
      state.loadingZoneConfigurationMapsStatus = 'pending';
    },

    [getBladeZoneConfigurationMap.fulfilled]: (state, action) => {
      state.loadingZoneConfigurationMapsStatus = 'fulfilled';
      if (action.payload.maps !== null) {
        //console.log(action.payload.maps)
        for (let [configurationId, maps] of Object.entries(action.payload.maps)) {
          let foundConfigurationMap = state.zoneConfigurationMaps.find((cM) => cM.id === parseInt(configurationId))
          if (foundConfigurationMap === undefined) {
            foundConfigurationMap = { id: parseInt(configurationId), component_map: {}, io_map: {} }
            state.zoneConfigurationMaps.push(foundConfigurationMap)
          }
          for (let [mapKey, map] of Object.entries(maps)) {
            foundConfigurationMap[mapKey] = map
          }
        }
      }
    },

    [getBladeZoneConfigurationMap.rejected]: (state) => {
      state.loadingZoneConfigurationMapsStatus = 'rejected';
    },



    [createNewBlade.pending]: (state) => {
      state.creatingNewBlade = 'pending';
    },
    [createNewBlade.fulfilled]: (state, action) => {
      state.creatingNewBlade = 'fulfilled';
      if (action.payload.blade) {
        state.blade.push(processBladeFromAPI(state, action.payload.blade))
        state.loadedBladeServiceIds.push(action.payload.blade.service_id)
        state.loadedBladeUIDs.push(action.payload.blade.uid)

        //Find the blade group and add this UID to it
        state.groups = state.groups.map((g) => {
          if (g.id !== action.meta.arg.bladeGroupId) {
            return g
          }
          return { ...g, blades: [...g.blades, action.meta.arg.bladeInfo["blade_uid"]] }
        })
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },
    [createNewBlade.rejected]: (state, action) => {
      state.creatingNewBlade = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [createNewBladeGroup.pending]: (state) => {
      state.creatingNewBladeGroup = 'pending';
    },

    [createNewBladeGroup.fulfilled]: (state, action) => {
      state.creatingNewBladeGroup = 'fulfilled';
      if (action.payload.success && action.payload.blade_group !== null) {
        let newGroup = processBladeGroupFromAPI(state, action.payload.blade_group)
        state.groups.push(newGroup)
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true, newGroup)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false, null)
        }
      }
    },

    [createNewBladeGroup.rejected]: (state, action) => {
      state.creatingNewBladeGroup = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false, null)
      }
    },

    [moveBladeToGroup.pending]: (state) => {
      state.linkingBladeToControlDevice = 'pending';
    },

    [moveBladeToGroup.fulfilled]: (state, action) => {
      state.movingBladeToGroup = 'fulfilled';
      if (action.payload.success) {
        //mark blade and blade group accordingly
        let oldBladeGroupId = null
        state.blade = state.blade.map((blade) => {
          if (blade.uid !== action.meta.arg.bladeUID) {
            return blade
          }
          oldBladeGroupId = blade.group_id
          return { ...blade, group_id: action.meta.arg.bladeGroupId }
        })
        state.groups = state.groups.map((bladeGroup) => {
          if (bladeGroup.id === oldBladeGroupId) {
            return { ...bladeGroup, blades: bladeGroup.blades.filter((b) => b !== action.meta.arg.bladeUID) }
          } else if (bladeGroup.id === action.meta.arg.bladeGroupId) {
            return { ...bladeGroup, blades: [...bladeGroup.blades, action.meta.arg.bladeUID] }
          }
          return bladeGroup
        })

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [moveBladeToGroup.rejected]: (state, action) => {
      state.movingBladeToGroup = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },

    [linkBladeToControlDevice.pending]: (state) => {
      state.linkingBladeToControlDevice = 'pending';
    },

    [linkBladeToControlDevice.fulfilled]: (state, action) => {
      state.linkingBladeToControlDevice = 'fulfilled';
      if (action.payload.success && action.payload.blade !== null) {
        let exists = false
        for (let bladeIndex in state.blade) {
          if (state.blade[bladeIndex].id === action.payload.blade.id) {
            //Get the UID

            state.blade[bladeIndex] = processBladeFromAPI(state, { ...state.blade[bladeIndex], ...action.payload.blade })
            exists = true
            break
          }
        }

        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [linkBladeToControlDevice.rejected]: (state, action) => {
      state.linkingBladeToControlDevice = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },



    [addBladeToFarm.pending]: (state) => {
      state.addingBladeToFarm = 'pending';
    },

    [addBladeToFarm.fulfilled]: (state, action) => {
      state.addingBladeToFarm = 'fulfilled';
      if (action.payload.confirmed && action.payload.blade) {
        let exists = false
        for (let bladeIndex in state.blade) {
          if (state.blade[bladeIndex].id === action.payload.blade.id) {
            //Get the UID

            state.blade[bladeIndex] = processBladeFromAPI(state, { ...state.blade[bladeIndex], ...action.payload.blade })
            exists = true
            break
          }
        }
        state.blade.push(processBladeFromAPI(state, action.payload.blade))
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      } else {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(false)
        }
      }
    },

    [addBladeToFarm.rejected]: (state, action) => {
      state.addingBladeToFarm = 'rejected';
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },



    [markGrowZoneForCleanse.pending]: (state, action) => {
      state.zones = state.zones.map((zone) => {
        if (zone.uid !== action.meta.arg.zoneUID) {
          return zone
        }

        return { ...zone, markingForCleanse: true }
      })
    },

    [markGrowZoneForCleanse.fulfilled]: (state, action) => {
      if (action.payload.success !== undefined && action.payload.success) {
        state.zones = state.zones.map((zone) => {
          if (zone.uid !== action.meta.arg.zoneUID) {
            return zone
          }

          return { ...zone, markingForCleanse: false }
        })

        state.blade = state.blade.map((blade) => {
          if (blade.id !== action.meta.arg.bladeId) {
            return blade
          }

          return { ...blade, runtime_information: { ...blade.runtime_information, "clean_state": "dirty" } }
        })

        if (action.payload.task_id && action.meta.arg.callback) {
          action.meta.arg.callback(true, action.payload.task_id)
        }
      } else {
        state.zones = state.zones.map((zone) => {
          if (zone.uid !== action.meta.arg.zoneUID) {
            return zone
          }

          return { ...zone, markingForCleanse: false }
        })

        if (action.meta.arg.callback) {
          action.meta.arg.callback(false, null)
        }
      }
    },

    [markGrowZoneForCleanse.rejected]: (state, action) => {
      state.zones = state.zones.map((zone) => {
        if (zone.uid !== action.meta.arg.zoneUID) {
          return zone
        }

        return { ...zone, markingForCleanse: false }
      })
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false, null)
      }
    },



    [getBladeCleanseSubtask.pending]: (state, action) => {

    },

    [getBladeCleanseSubtask.fulfilled]: (state, action) => {
      if (action.payload.success !== undefined && action.payload.sub_task !== undefined && action.payload.success) {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true, action.payload.sub_task)
        }
      }
    },

    [getBladeCleanseSubtask.rejected]: (state, action) => {
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false, null)
      }
    },


    [setBladeCleanseSubtaskStage.pending]: (state, action) => {

    },

    [setBladeCleanseSubtaskStage.fulfilled]: (state, action) => {
      if (action.payload.success !== undefined && action.payload.success) {
        if (action.meta.arg.callback) {
          action.meta.arg.callback(true)
        }
      }
    },

    [setBladeCleanseSubtaskStage.rejected]: (state, action) => {
      if (action.meta.arg.callback) {
        action.meta.arg.callback(false)
      }
    },




    [validateBladeSystemConnection.pending]: (state) => {
      state.validatingBladeConnectedToSystem = 'pending';
    },

    [validateBladeSystemConnection.fulfilled]: (state, action) => {
      state.validatingBladeConnectedToSystem = 'fulfilled';
      if (action.payload.success && action.payload.connected !== null) {


        if (action.payload.connected) {
          if (action.meta.arg.callback !== undefined) {
            action.meta.arg.callback(true)
          }
        } else {
          if (action.meta.arg.callback !== undefined) {
            action.meta.arg.callback(false)
          }
        }
      } else {
        if (action.meta.arg.callback !== undefined) {
          action.meta.arg.callback(false)
        }
      }
    },

    [validateBladeSystemConnection.rejected]: (state, action) => {
      state.validatingBladeConnectedToSystem = 'rejected';
      if (action.meta.arg.callback !== undefined) {
        action.meta.arg.callback(false)
      }
    },

  }
})





/* Request Calls */


/*Load blade status data*/
export const MaintainBladeStatus = ({ bladeIds, bladeUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allBlades = useSelector((state) => selectAllBlades(state))
  /*Load blade status data*/
  const validateBladeStatusDataToLoad = () => {
    let bladesToLoadStatusData = []
    let currentTime = new Date().getTime()

    let bladesToValidate = []
    if (bladeIds !== undefined) {
      for (let bladeId of Object.values(bladeIds)) {
        let foundBlade = allBlades.find((b) => b.id === bladeId)
        if (foundBlade !== undefined) {
          bladesToValidate.push(foundBlade)
        }
      }
    }
    if (bladeUIDs !== undefined) {
      for (let bladeUID of Object.values(bladeUIDs)) {
        let foundBlade = allBlades.find((b) => b.uid === bladeUID)
        if (foundBlade !== undefined) {
          if (bladesToValidate.find((b) => b.uid === bladeUID) === undefined) {
            bladesToValidate.push(foundBlade)
          }
        }
      }
    }
    for (let blade of bladesToValidate) {
      let requiresStatusUpdate = false
      if (blade.loading_status_data === "idle") {
        requiresStatusUpdate = true
      } else if (blade.loading_status_data === "fulfilled" || blade.loading_status_data === "rejected") {
        if (blade.last_status_data_loaded_on !== undefined) {
          let elapsedTime = currentTime - blade.last_status_data_loaded_on
          if (elapsedTime > interval) {
            requiresStatusUpdate = true
          }
        }
      }
      if (requiresStatusUpdate) {
        bladesToLoadStatusData.push(blade.id)
      }
    }

    if (bladesToLoadStatusData.length > 0) {
      dispatch(getBladeStatusById({ bladeIds: bladesToLoadStatusData }))
    }
  }
  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateBladeStatusDataToLoad()
    }, interval / 10);
    validateBladeStatusDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allBlades, bladeIds, bladeUIDs]);
}

export const MaintainBladeUniqueConfigurationMaps = ({ bladeIds, bladeUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allBlades = useSelector((state) => selectAllBlades(state))
  /*Load blade status data*/
  const validateBladeUniqueConfigurationMapsToLoad = () => {
    let bladesToLoadUniqueConfigurationMaps = []
    let currentTime = new Date().getTime()

    let bladesToValidate = []
    if (bladeIds !== undefined) {
      for (let bladeId of Object.values(bladeIds)) {
        let foundBlade = allBlades.find((b) => b.id === bladeId)
        if (foundBlade !== undefined) {
          bladesToValidate.push(foundBlade)
        }
      }
    }
    if (bladeUIDs !== undefined) {
      for (let bladeUID of Object.values(bladeUIDs)) {
        let foundBlade = allBlades.find((b) => b.uid === bladeUID)
        if (foundBlade !== undefined) {
          if (bladesToValidate.find((b) => b.uid === bladeUID) === undefined) {
            bladesToValidate.push(foundBlade)
          }
        }
      }
    }
    for (let blade of bladesToValidate) {
      if (blade.loading_unique_configuration_map !== "pending") {
        if (blade.unique_configuration === null || blade.unique_configuration_version !== blade.unique_configuration.version) {
          bladesToLoadUniqueConfigurationMaps.push(blade.id)
        }
      }
    }

    if (bladesToLoadUniqueConfigurationMaps.length > 0) {
      //dispatch(getBladeStatusById({bladeIds: bladesToLoadUniqueConfigurationMaps}))
    }
  }
  useEffect(() => {
    const uniqueConfigurationMapsLoadInterval = setInterval(() => {
      validateBladeUniqueConfigurationMapsToLoad()
    }, interval / 10);
    validateBladeUniqueConfigurationMapsToLoad()
    return () => clearInterval(uniqueConfigurationMapsLoadInterval);
  }, [allBlades, bladeIds, bladeUIDs]);
}




export const MaintainBladeLiveData = ({ bladeIds, bladeUIDs, interval = 1000 }) => {
  const dispatch = useDispatch()
  const allBlades = useSelector((state) => selectAllBlades(state))

  const validateBladeLiveDataToLoad = () => {
    let bladesToLoadLiveData = []
    let bladeLastLoadedLiveDataElapsedTimes = {}
    let currentTime = new Date().getTime()

    let bladesToValidate = []
    if (bladeIds !== undefined) {
      for (let bladeId of Object.values(bladeIds)) {
        let foundBlade = allBlades.find((b) => b.id === bladeId)
        if (foundBlade !== undefined) {
          bladesToValidate.push(foundBlade)
        }
      }
    }
    if (bladeUIDs !== undefined) {
      for (let bladeUID of Object.values(bladeUIDs)) {
        let foundBlade = allBlades.find((b) => b.uid === bladeUID)
        if (foundBlade !== undefined) {
          if (bladesToValidate.find((b) => b.uid === bladeUID) === undefined) {
            bladesToValidate.push(foundBlade)
          }
        }
      }
    }
    for (let blade of bladesToValidate) {
      if (blade.loading_live_data === "idle") {
        bladesToLoadLiveData.push(blade.id)
      } else if (blade.loading_live_data === "fulfilled" || blade.loading_live_data === "rejected") {
        if (blade.last_live_data_loaded_on !== undefined) {
          bladeLastLoadedLiveDataElapsedTimes[blade.id] = currentTime - blade.last_live_data_loaded_on
        }
      }
    }
    for (let elapsedTime of Object.values(bladeLastLoadedLiveDataElapsedTimes)) {
      if (elapsedTime > interval) {
        bladesToLoadLiveData.push(...Object.keys(bladeLastLoadedLiveDataElapsedTimes).map((bladeIdAsString) => parseInt(bladeIdAsString)))
        break
      }
    }
    if (bladesToLoadLiveData.length > 0) {
      dispatch(getBladeLiveDataById({ bladeIds: bladesToLoadLiveData }))
    }
  }

  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateBladeLiveDataToLoad()
    }, interval / 10);
    validateBladeLiveDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allBlades, bladeIds, bladeUIDs]);
}






export const MaintainBladeAnalyticsData = ({ bladeComponents, chartOriginDate, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType, interval = 2000 }) => {
  const dispatch = useDispatch()
  const allBlades = useSelector((state) => selectAllBlades(state))
  const dataRecordingTimePeriodTypes = useSelector(selectAllDataRecordingTimePeriodTypes)
  const bladeAnalyticsData = useSelector((state) => selectBladeAnalyticsDataForBladeIds(state, Object.keys(bladeComponents).map((bId) => parseInt(bId))), _.isEqual)

  //const loadingBladeAnalyticsData = useSelector((state) => state.blade.loadingBladeAnalyticsData)

  const bladesRef = useRef(allBlades)
  useEffect(() => {
    bladesRef.current = allBlades
  }, [allBlades])

  const bladeAnalyticsDataRef = useRef(bladeAnalyticsData)
  useEffect(() => {
    bladeAnalyticsDataRef.current = bladeAnalyticsData
  }, [bladeAnalyticsData])


  const bladeComponentsRef = useRef(bladeComponents)
  useEffect(() => {
    bladeComponentsRef.current = bladeComponents
  }, [bladeComponents])

  const mainRangeRef = useRef(mainRange)
  useEffect(() => {
    mainRangeRef.current = mainRange
  }, [mainRange])

  const overviewRangeRef = useRef(overviewRange)
  useEffect(() => {
    overviewRangeRef.current = overviewRange
  }, [overviewRange])

  const mainDataRecordingTimePeriodTypeRef = useRef(mainDataRecordingTimePeriodType)
  useEffect(() => {
    mainDataRecordingTimePeriodTypeRef.current = mainDataRecordingTimePeriodType
  }, [mainDataRecordingTimePeriodType])

  const overviewDataRecordingTimePeriodTypeRef = useRef(overviewDataRecordingTimePeriodType)
  useEffect(() => {
    overviewDataRecordingTimePeriodTypeRef.current = overviewDataRecordingTimePeriodType
  }, [overviewDataRecordingTimePeriodType])

  const validateBladeAnalyticsDataToLoad = () => {
    const allBlades = bladesRef.current
    const bladeAnalyticsData = bladeAnalyticsDataRef.current
    const bladeComponents = bladeComponentsRef.current
    const mainRange = mainRangeRef.current
    const overviewRange = overviewRangeRef.current
    const mainDataRecordingTimePeriodType = mainDataRecordingTimePeriodTypeRef.current
    const overviewDataRecordingTimePeriodType = overviewDataRecordingTimePeriodTypeRef.current


    //console.log(allBlades, bladeAnalyticsData, bladeComponents, mainRange, overviewRange, mainDataRecordingTimePeriodType, overviewDataRecordingTimePeriodType)
    let analyticsRequestEntries = {}

    let bladesToLoadAnalyticsData = []
    let currentTime = new Date().getTime()
    for (let bladeId of Object.keys(bladeComponents)) {
      let blade = allBlades.find((b) => b.id === parseInt(bladeId))
      if (blade !== undefined) {
        if (blade.loading_analytics_data === "idle") {
          bladesToLoadAnalyticsData.push(blade)
        } else if (blade.loading_analytics_data === "fulfilled" || blade.loading_analytics_data === "rejected") {
          if (blade.last_analytics_data_loaded_on !== undefined) {
            if (currentTime - blade.last_analytics_data_loaded_on > interval) {
              bladesToLoadAnalyticsData.push(blade)
            }
          }
        }
      }
    }

    const addComponentRequest = (timePeriodTypeId, requestFrom, requestTo, bladeId, componentId) => {
      let requestKey = requestFrom + "-" + requestTo
      if (analyticsRequestEntries[timePeriodTypeId] === undefined) {
        analyticsRequestEntries[timePeriodTypeId] = {}
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey] === undefined) {
        analyticsRequestEntries[timePeriodTypeId][requestKey] = {}
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey][bladeId] === undefined) {
        analyticsRequestEntries[timePeriodTypeId][requestKey][bladeId] = []
      }
      if (analyticsRequestEntries[timePeriodTypeId][requestKey][bladeId].indexOf(componentId) === -1) {
        analyticsRequestEntries[timePeriodTypeId][requestKey][bladeId].push(componentId)
      }
    }


    for (let blade of bladesToLoadAnalyticsData) {
      for (const componentId of bladeComponents[blade.id]) {
        if (bladeAnalyticsData[blade.id] !== undefined && bladeAnalyticsData[blade.id][componentId] !== undefined) {

          let analyticsData = bladeAnalyticsData[blade.id][componentId]
          let mainAnalyticsData = analyticsData[mainDataRecordingTimePeriodType.id]
          let overviewAnalyticsData = analyticsData[overviewDataRecordingTimePeriodType.id]
          //Check if we can load overview data

          if (overviewAnalyticsData !== undefined) {
            let overviewFromEntryIndex = Math.floor((overviewRange.start / 1000) / (overviewDataRecordingTimePeriodType.duration))
            let overviewToEntryIndex = Math.floor((overviewRange.end / 1000) / (overviewDataRecordingTimePeriodType.duration))
            //console.log(overviewAnalyticsData.dataChunks)
            for (let entryIndex = overviewFromEntryIndex; entryIndex <= overviewToEntryIndex; entryIndex++) {
              if (overviewAnalyticsData.dataChunks[entryIndex] !== undefined) {
                //console.log(overviewAnalyticsData.dataChunks[entryIndex])
                if (!overviewAnalyticsData.dataChunks[entryIndex].completed) {
                  //Lets build a request for the portion of data we don't have
                  let requestRange = { "from": overviewAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * overviewDataRecordingTimePeriodType.duration * 1000 }
                  if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                    addComponentRequest(overviewDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], blade.id, componentId)
                  }
                }
              } else {
                addComponentRequest(overviewDataRecordingTimePeriodType.id, overviewRange.start, overviewRange.end, blade.id, componentId)
              }

            }
          }
          if (overviewDataRecordingTimePeriodType != mainDataRecordingTimePeriodType) {
            //Check if we can load main data
            let mainFromEntryIndex = Math.floor((mainRange.start / 1000) / (mainDataRecordingTimePeriodType.duration))
            let mainToEntryIndex = Math.floor((mainRange.end / 1000) / (mainDataRecordingTimePeriodType.duration))


            //console.log(mainAnalyticsData.dataChunks)
            for (let entryIndex = mainFromEntryIndex; entryIndex <= mainToEntryIndex; entryIndex++) {
              if (mainAnalyticsData.dataChunks[entryIndex] !== undefined) {
                //console.log(mainAnalyticsData.dataChunks[entryIndex])
                if (!mainAnalyticsData.dataChunks[entryIndex].completed) {
                  //Lets build a request for the portion of data we don't have
                  let requestRange = { "from": mainAnalyticsData.haveDataUpUntil + 1, "to": (entryIndex + 1) * mainDataRecordingTimePeriodType.duration * 1000 }
                  if (requestRange["to"] - requestRange["from"] > 1000) { //minimum request range
                    addComponentRequest(mainDataRecordingTimePeriodType.id, requestRange["from"], requestRange["to"], blade.id, componentId)
                  }
                }
              } else {
                addComponentRequest(mainDataRecordingTimePeriodType.id, mainRange.start, mainRange.end, blade.id, componentId)
              }

            }
          }
        }
      }
    }

    if (Object.entries(analyticsRequestEntries).length > 0) {
      //console.log(analyticsRequestEntries)
      dispatch(getBladeAnalyticsData({ entries: analyticsRequestEntries, timePeriodTypes: dataRecordingTimePeriodTypes, dateOffset: chartOriginDate }))
    }
  }

  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateBladeAnalyticsDataToLoad()
    }, interval);
    validateBladeAnalyticsDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [bladesRef, bladeAnalyticsDataRef, bladeComponentsRef, mainRangeRef, overviewRangeRef, mainDataRecordingTimePeriodTypeRef, overviewDataRecordingTimePeriodTypeRef]);
}





export const MaintainBladeZoneStatus = ({ bladeZoneUIDs, interval = 2000 }) => {

  const dispatch = useDispatch()
  const allBladeZones = useSelector((state) => selectAllBladeZones(state))
  /*Load blade status data*/
  const validateBladeZoneStatusDataToLoad = () => {
    let bladeZonesToLoadStatusData = []
    let bladeZoneLastLoadedStatusDataElapsedTimes = {}
    let currentTime = new Date().getTime()

    if (bladeZoneUIDs !== undefined) {
      for (let bladeZoneUID of Object.values(bladeZoneUIDs)) {
        let foundBladeZone = allBladeZones.find((b) => b.uid === bladeZoneUID)
        if (foundBladeZone !== undefined) {
          if (foundBladeZone.loading_status_data === "idle") {
            bladeZonesToLoadStatusData.push(foundBladeZone.uid)
          } else if (foundBladeZone.loading_status_data === "fulfilled" || foundBladeZone.loading_status_data === "rejected") {
            if (foundBladeZone.last_status_data_loaded_on !== undefined) {
              bladeZoneLastLoadedStatusDataElapsedTimes[foundBladeZone.uid] = currentTime - foundBladeZone.last_status_data_loaded_on
            }
          }
        }
      }
    }

    for (let elapsedTime of Object.values(bladeZoneLastLoadedStatusDataElapsedTimes)) {
      if (elapsedTime > interval) {
        bladeZonesToLoadStatusData.push(...Object.keys(bladeZoneLastLoadedStatusDataElapsedTimes))
        break
      }
    }
    if (bladeZonesToLoadStatusData.length > 0) {
      dispatch(getBladeZoneStatusByUID({ bladeZoneUIDs: bladeZonesToLoadStatusData }))
    }
  }
  useEffect(() => {
    const statusLoadInterval = setInterval(() => {
      validateBladeZoneStatusDataToLoad()
    }, interval / 10);
    validateBladeZoneStatusDataToLoad()
    return () => clearInterval(statusLoadInterval);
  }, [allBladeZones, bladeZoneUIDs]);

}



export const useLoadAllBladeGroupsForFacility = ({ facilityId }) => {

  const dispatch = useDispatch()
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  useEffect(() => {
    if (!loadedInitialBladeGroups && loadingGroupsStatus !== "pending") {
      dispatch(getAllBladeGroupsByFacilityId({ facilityIds: [facilityId] }))
    }
  }, [loadedInitialBladeGroups, loadingGroupsStatus, facilityId])
}


export const useLoadAllBladesByUIDs = ({ bladeUIDs }) => {
  const dispatch = useDispatch()
  const allBlades = useSelector(state => selectAllBlades(state))
  const loadingBladesStatus = useSelector(state => state.blade.loadingBladeStatus)
  useEffect(() => {
    if (loadingBladesStatus !== "pending") {
      let bladeUIDsToLoad = []
      for (let bladeUID of bladeUIDs) {
        if (allBlades.find((b) => b.uid === bladeUID) === undefined) {
          bladeUIDsToLoad.push(bladeUID)
        }
      }
      if (bladeUIDsToLoad.length > 0) {
        dispatch(getBladeByUID({ bladeUIDs: bladeUIDsToLoad }))
      }
    }
  }, [allBlades, bladeUIDs, loadingBladesStatus])
}

export const useLoadAllBladeZonesByUIDs = ({ bladeZoneUIDs }) => {
  const dispatch = useDispatch()
  const allBladeZones = useSelector(state => selectAllBladeZones(state))
  const loadingBladeZonesStatus = useSelector(state => state.blade.loadingBladeZoneStatus)
  useEffect(() => {
    if (loadingBladeZonesStatus !== "pending") {
      let bladeZoneUIDsToLoad = []
      for (let bladeZoneUID of bladeZoneUIDs) {
        if (allBladeZones.find((bZ) => bZ.uid === bladeZoneUID) === undefined) {
          bladeZoneUIDsToLoad.push(bladeZoneUID)
        }
      }
      if (bladeZoneUIDsToLoad.length > 0) {
        dispatch(getBladeZoneByUID({ UIDs: bladeZoneUIDsToLoad }))
      }
    }
  }, [allBladeZones, bladeZoneUIDs, loadingBladeZonesStatus])
}


// Action creators are generated for each case reducer function
export const { initializeComponentForAnalyticsData, assignActiveRecipeToZone, disassignActiveRecipeForZone } = bladesSlice.actions

export default bladesSlice.reducer

export const selectAllBlades = state => state.blade.blade
export const selectAllBladeServiceIds = (state) => state.blade.loadedBladeServiceIds
export const selectAllBladeUIDs = (state) => state.blade.loadedBladeUIDs
export const selectBladeById = (state, bladeId) => state.blade.blade.find((b) => b.id === bladeId)
export const selectBladeByUID = (state, bladeUID) => state.blade.blade.find((b) => b.uid === bladeUID)
export const selectBladeBySerialNumber = (state, bladeSerialNumber) => state.blade.blade.find((b) => b.serial_number === bladeSerialNumber)


export const selectBladeConfigurationIdByUIDs = (state, bladeUIDs) => {
  let bladeConfigurationIds = {}
  let blades = state.blade.blade.filter((b) => bladeUIDs.includes(b.uid))
  for (let blade of blades) {
    bladeConfigurationIds[blade.uid] = blade.configuration_id
  }
  return bladeConfigurationIds
}
export const selectBladeAnalyticsDataForBladeIds = (state, bladeIds) => {
  let bladeAnalyticsData = {}
  let blades = state.blade.blade.filter((b) => bladeIds.includes(b.id))
  for (let blade of blades) {
    bladeAnalyticsData[blade.id] = blade.analyticsData
  }
  return bladeAnalyticsData
}


export const selectAllBladeGroups = state => state.blade.groups


export const selectAllBladeZones = state => state.blade.zones
export const selectAllBladeConfigurationMaps = state => state.blade.configurationMaps
export const selectAllBladeZoneConfigurationMaps = state => state.blade.zoneConfigurationMaps
export const selectBladeZoneByUID = (state, bladeZoneUID) => state.blade.zones.find((bz) => bz.uid === bladeZoneUID)


export const selectBladeZoneIdByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.id
  }
  return -1
}
export const selectBladeZoneDisplayNameByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.display_name
  }
  return "Unknown Zone"
}
export const selectBladeZoneTypeByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.zone_type
  }
  return undefined
}
export const selectBladeZoneConfigurationIdByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.configuration_id
  }
  return 0
}
export const selectAllBladeZoneComponentsByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.components
  }
  return []
}

export const selectBladeZoneRuntimeInformationByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.runtime_information
  }
  return []
}
export const selectBladeZoneEventsByUID = (state, bladeZoneUID) => {
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    return bladeZone.events
  }
  return []
}



export const selectBladeUIDsForBladeZoneUID = (state, bladeZoneUID) => {
  let bladeUIDs = {}
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    if (bladeZone.zone_type === "nursery") {
      if (bladeZone.zone_props["rack_uid"] !== undefined && bladeZone.zone_props["rack_uid"] !== "") {
        bladeUIDs["nursery"] = bladeZone.zone_props["rack_uid"]
      }
    } else {
      if (bladeZone.zone_props["grow_rack_uid"] !== undefined && bladeZone.zone_props["grow_rack_uid"] !== "") {
        bladeUIDs["grow"] = bladeZone.zone_props["grow_rack_uid"]
      }
      if (bladeZone.zone_props["environment_rack_uid"] !== undefined && bladeZone.zone_props["environment_rack_uid"] !== "") {
        bladeUIDs["environment"] = bladeZone.zone_props["environment_rack_uid"]
      }
    }
  }
  return bladeUIDs
}


export const selectBladeIdsForBladeZoneUID = (state, bladeZoneUID, key = "type") => {
  let bladeIds = {}
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    if (bladeZone.zone_type === "nursery") {
      if (bladeZone.zone_props["rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["rack_uid"])
        if (foundBlade !== undefined) {
          if (key === "type") {
            bladeIds["nursery"] = foundBlade.id
          } else if (key === "uid") {
            bladeIds[foundBlade.uid] = foundBlade.id
          }
        }
      }
    } else {
      if (bladeZone.zone_props["grow_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["grow_rack_uid"])
        if (foundBlade !== undefined) {
          if (key === "type") {
            bladeIds["grow"] = foundBlade.id
          } else if (key === "uid") {
            bladeIds[foundBlade.uid] = foundBlade.id
          }
        }
      }
      if (bladeZone.zone_props["environment_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["environment_rack_uid"])
        if (foundBlade !== undefined) {
          if (key === "type") {
            bladeIds["environment"] = foundBlade.id
          } else if (key === "uid") {
            bladeIds[foundBlade.uid] = foundBlade.id
          }
        }
      }
    }
  }
  return bladeIds
}


export const selectBladeDisplayNamesForBladeZoneUID = (state, bladeZoneUID) => {
  let bladeDisplayNames = {}
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    if (bladeZone.zone_type === "nursery") {
      if (bladeZone.zone_props["rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["rack_uid"])
        if (foundBlade !== undefined) {
          bladeDisplayNames["nursery"] = foundBlade.display_name
        }
      }
    } else {
      if (bladeZone.zone_props["grow_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["grow_rack_uid"])
        if (foundBlade !== undefined) {
          bladeDisplayNames["grow"] = foundBlade.display_name
        }
      }
      if (bladeZone.zone_props["environment_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["environment_rack_uid"])
        if (foundBlade !== undefined) {
          bladeDisplayNames["environment"] = foundBlade.display_name
        }
      }
    }
  }
  return bladeDisplayNames
}



export const selectBladeRuntimeInformationForBladeZoneUID = (state, bladeZoneUID) => {
  let bladeRuntimeInformation = {}
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    if (bladeZone.zone_type === "nursery") {
      if (bladeZone.zone_props["rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["rack_uid"])
        if (foundBlade !== undefined) {
          bladeRuntimeInformation["nursery"] = foundBlade.runtime_information
        }
      }
    } else {
      if (bladeZone.zone_props["grow_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["grow_rack_uid"])
        if (foundBlade !== undefined) {
          bladeRuntimeInformation["grow"] = foundBlade.runtime_information
        }
      }
      if (bladeZone.zone_props["environment_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["environment_rack_uid"])
        if (foundBlade !== undefined) {
          bladeRuntimeInformation["environment"] = foundBlade.runtime_information
        }
      }
    }
  }
  return bladeRuntimeInformation
}

export const selectBladeControlDevicesForBladeZoneUID = (state, bladeZoneUID) => {
  let bladeControlDevices = {}
  let bladeZone = state.blade.zones.find((bz) => bz.uid === bladeZoneUID)
  if (bladeZone !== undefined) {
    if (bladeZone.zone_type === "nursery") {
      if (bladeZone.zone_props["rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["rack_uid"])
        if (foundBlade !== undefined) {
          bladeControlDevices["nursery"] = foundBlade.control_device
        }
      }
    } else {
      if (bladeZone.zone_props["grow_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["grow_rack_uid"])
        if (foundBlade !== undefined) {
          bladeControlDevices["grow"] = foundBlade.control_device
        }
      }
      if (bladeZone.zone_props["environment_rack_uid"] !== undefined) {
        let foundBlade = state.blade.blade.find((b) => b.uid === bladeZone.zone_props["environment_rack_uid"])
        if (foundBlade !== undefined) {
          bladeControlDevices["environment"] = foundBlade.control_device
        }
      }
    }
  }
  return bladeControlDevices
}



export const selectBladeZonePauseState = (state, bladeZoneUID) => {
  const pauseState = {}

  const bladeZone = state.blade.zones.find((bZ) => bZ.uid === bladeZoneUID)
  if (bladeZone !== undefined) {

    let isPaused, isPausing, isResuming = undefined
    if (bladeZone.zone_type === "nursery") {
      if (bladeZone.runtime_information["rack_control_status"] !== undefined) {
        isPaused = bladeZone.runtime_information["rack_control_status"] !== "active"
        isPausing = bladeZone.runtime_information["rack_control_status"] === "pause"
        isResuming = bladeZone.runtime_information["rack_control_status"] === "resume"
      }
      if (bladeZone.runtime_information_requested["rack_control_status"] !== undefined) {
        if (bladeZone.runtime_information_requested["rack_control_status"] === "pause") {
          isPausing = true
        } else if (bladeZone.runtime_information_requested["rack_control_status"] === "resume") {
          isResuming = true
        }
      }
    } else {
      if (bladeZone.runtime_information["environment_rack_control_status"] !== undefined && bladeZone.runtime_information["grow_rack_control_status"] !== undefined) {
        isPaused = bladeZone.runtime_information["environment_rack_control_status"] !== "active" || bladeZone.runtime_information["grow_rack_control_status"] !== "active"
        isPausing = bladeZone.runtime_information["environment_rack_control_status"] === "pause" || bladeZone.runtime_information["grow_rack_control_status"] === "pause"
        isResuming = bladeZone.runtime_information["environment_rack_control_status"] === "resume" || bladeZone.runtime_information["grow_rack_control_status"] === "resume"
      }
      if (bladeZone.runtime_information_requested["environment_rack_control_status"] !== undefined && bladeZone.runtime_information_requested["grow_rack_control_status"] !== undefined) {
        if (bladeZone.runtime_information_requested["environment_rack_control_status"] === "pause" || bladeZone.runtime_information_requested["grow_rack_control_status"] === "pause") {
          isPausing = true
        } else if (bladeZone.runtime_information_requested["environment_rack_control_status"] === "resume" || bladeZone.runtime_information_requested["grow_rack_control_status"] === "resume") {
          isResuming = true
        }
      }
    }

    if (isPaused !== undefined && isPausing !== undefined && isResuming !== undefined) {
      pauseState["paused"] = isPaused
      pauseState["pausing"] = isPausing
      pauseState["resuming"] = isResuming
    }
  }
  return pauseState
}


export const selectBladeIssuesByBladeId = (state, bladeId) => {
  let foundBlade = state.blade.blade.find((b) => b.id === bladeId)
  if (foundBlade !== undefined) {
    return foundBlade.issues
  }
  return {}
}

export const selectVerticalRackGroupByZoneId = (state, zoneId) => {
  let foundGroup = undefined
  if (state.blade !== undefined) {
    state.blade.verticalRackGroups.map((verticalRackGroup) => {

      if (verticalRackGroup.zone_map.zones.find((z) => z.id === zoneId) !== undefined) {
        foundGroup = verticalRackGroup
        return
      }
    })
  }
  return foundGroup
}
export const selectVerticalRackZoneById = (state, zoneId) => {
  let foundZone = undefined
  if (state.blade !== undefined) {
    state.blade.verticalRackGroups.map((verticalRackGroup) => {

      let currentZone = verticalRackGroup.zone_map.zones.find((z) => z.id === zoneId)
      if (currentZone !== undefined) {
        foundZone = currentZone
        return
      }
    })
  }
  return foundZone
}





export const pauseZone = (bladeZoneUID, bladeZoneType, dispatch) => {
  const defaultTimeToPauseFor = 10 * 60

  let resumeAt = Math.floor((new Date().getTime() / 1000) + defaultTimeToPauseFor)
  if (bladeZoneType === "nursery") {
    dispatch(setBladeZoneRuntimeProperty({
      bladeZoneUID: bladeZoneUID,
      properties: { "rack_control_status": "pause", "resume_controls_on": resumeAt.toString() },
      callback: (success) => {

      }
    }))
  } else {
    dispatch(setBladeZoneRuntimeProperty({
      bladeZoneUID: bladeZoneUID,
      properties: { "environment_rack_control_status": "pause", "grow_rack_control_status": "pause", "resume_controls_on": resumeAt.toString() },
      callback: (success) => {

      }
    }))
  }
}




export const resumeZone = (bladeZoneUID, bladeZoneType, dispatch) => {
  const defaultTimeToPauseFor = 10 * 60

  if (bladeZoneType === "nursery") {
    dispatch(setBladeZoneRuntimeProperty({
      bladeZoneUID: bladeZoneUID,
      properties: { "rack_control_status": "resume" },
      callback: (success) => {

      }
    }))
  } else {
    dispatch(setBladeZoneRuntimeProperty({
      bladeZoneUID: bladeZoneUID,
      properties: { "environment_rack_control_status": "resume", "grow_rack_control_status": "resume" },
      callback: (success) => {

      }
    }))
  }
}
