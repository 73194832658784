import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';
import { SoftWarning } from '../../../../assets/icons/Icons';


const HarvestTask_PrepareForHarvestWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        allBladeZones,
        harvestingZoneUID,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });



    const backClicked = () => {
        transitionToPreviousStep(["zone_list"], null)
    }
    const continueClicked = () => {
        transitionToNextStep(["container_options", null])        
    }

    let activeBladeZone = allBladeZones.find((bZ) => bZ.uid === harvestingZoneUID)

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Prepare to harvest {activeBladeZone !== undefined && activeBladeZone.display_name}
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"} style={{flex:1}}>
                    <div className={"FlexContent-30 FlexContent-Center"} style={{padding: isMobile ? "0px 10px" : "0px 100px"}}>
                        <div className="FlexContent-H-10 Linebreak-30">
                            <SoftWarning fill={"#EC3C3C"}/>
                            <div className="Text-Medium-S16 Text-Wrap">The zone will be paused for the duration of the harvest meaning:</div>
                        </div>
                        <div className="FlexContent-30" style={{alignSelf:"stretch"}}>
                            <div className="Text-S16">Lights will turn to <span style={{fontStyle:"italic"}}>Nice Light</span></div>
                            {activeBladeZone !== undefined && <div className="Text-S16">
                                {activeBladeZone.zone_type === "grow_boards" && "Aeroponics"}
                                {activeBladeZone.zone_type === "berry_troughs" && "Drip Irrigation"}
                                &nbsp;will be disabled
                            </div>}
                            <div className="Text-S16">The reservoir will drain and all other functions will stop</div>
                        </div>
                    </div>                    
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Continue"} onClick={continueClicked} size={"Flex50"}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_PrepareForHarvestWalkthroughStep