import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';


const HarvestTask_ZoneCleanOptionsWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        allBladeZones,
        harvestingZoneUID,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    const backClicked = () => {
        transitionToPreviousStep(["container_list", null])
    }
    const completeClicked = () => {

    }


    const [cleaningOption, SetCleaningOption] = React.useState(null)
    

    let activeBladeZone = allBladeZones.find((bZ) => bZ.uid === harvestingZoneUID)

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Does {activeBladeZone !== undefined && activeBladeZone.display_name} need cleaning?
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={isMobile ? "FlexContent-10" : "FlexContent-H-10"} style={{flex:1}}>
                    <Button content={<>
                        <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                            <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                                <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>Yes, Mark zone as dirty</div>
                                <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>Cleanse tasks will be created to take care of this zone before another grow can run</div>
                            </div>
                        </div>
                    </>} status={"Primary-Toggle"} state={cleaningOption === "clean"} width={"Flex100"} onClick={() => {SetCleaningOption("clean")}}/>
                <Button content={<>
                    <div className="FlexContent-Center" style={{padding:"0 50px"}}>
                        <div className={isMobile ? "FlexContent-10" : "FlexContent-15"} style={{alignItems:"flex-start"}}>
                            <div className={(isMobile ? "Text-Medium-S20" : "Text-Medium-S24") + " Text-Wrap"} style={{textAlign:"left"}}>No, Skip cleaning</div>
                            <div className={(isMobile ? "Text-S14" : "Text-S18") + " Text-Wrap"} style={{textAlign:"left"}}>The zone will be ready for another grow immediately</div>
                        </div>
                    </div>
                    </>} status={"Primary-Toggle"} state={cleaningOption === "skip"} width={"Flex100"} onClick={() => {SetCleaningOption("skip")}}/>
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Back"} status={"Neutral"} onClick={backClicked} size={"Flex50"}/>
                <Button status="Primary" content={"Complete"} onClick={completeClicked} size={"Flex50"} disabled={cleaningOption === null}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ZoneCleanOptionsWalkthroughStep