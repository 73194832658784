import './PlantSeedsForGerminationTask.scss';

import React from 'react';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllGrowSeeds, selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import RowGroup from '../../../../components/RowGroup/RowGroup';

const PlantSeedsForGermination_SeedingListWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        initialSeederChoices,
        seedingBegun,
        seedingCompleted,
        variantLocationsTracked,
        seedingPlantVariantIndex,
        seederChoicesConfirmedCallback,
        finishedSeedingCallback,
        performingPlantSeedsCompleted,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });



    const beginSeedingClicked = () => {
        seederChoicesConfirmedCallback({seederChoices: seederChoices})
        
        transitionToNextStep(["seeding", null])
    }
    const finishedSeedingClicked = () => {
        finishedSeedingCallback()
    }

    const beginTrackingClicked = (variantId) => {

    }


       
    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Seed</>, setPosition: (bounds, contentBounds) => {return {left: bounds.left}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
            units: {label: <>Units</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth}}},
            seeder: {label: <>{seedingBegun ? "Tracking" : "Seeder"}</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}}  
        }
    }
    
    const [plantVariantList, SetPlantVariantList] = React.useState([])
    React.useEffect(() => {
        let variantList = []
        if (activeGrowPlan !== undefined)   {
            for (let variant of Object.values(activeGrowPlan.plant_variants))   {
                variantList.push({
                    data: variant
                })
            }
        }
        SetPlantVariantList(variantList)
    }, [activeGrowPlan])

    const [seederChoices, SetSeederChoices] = React.useState({})
    React.useLayoutEffect(() => {
        if (initialSeederChoices !== null)  {
            SetSeederChoices(initialSeederChoices)
        }
    }, [initialSeederChoices])
    const seederChoiceUpdated = (seedId, choice) => {

        SetSeederChoices({...seederChoices, [seedId]: choice})
    }



    

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Select Seeding Methods and Begin Seeding
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className={isMobile ? "FlexContent-20" : "FlexContent-30"} style={{padding: isMobile ? "20px" : "40px 60px"}}>
                        
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}
                            hasSubGroups={false}
                            hasContainer={false}
                            >
                            
                                <PlantSeedsForGermination_PlantVariantList_Row isPlaceholder={true} columnSizesChanged={(columns) => {
                                    SetColumnSizes(columns)
                                }}/>
                                {plantVariantList.map((plantVariant) => {
                                    let seederChoice = "n/a"
                                    if (seederChoices[plantVariant.data.id] !== undefined)  {
                                        seederChoice = seederChoices[plantVariant.data.id]
                                    }

                                    let variantTrackingOption = null
                                    if (variantLocationsTracked !== null && variantLocationsTracked[plantVariant.data.id] !== undefined)   {
                                        variantTrackingOption = variantLocationsTracked[plantVariant.data.id]
                                    }
                                    return <>
                                        <PlantSeedsForGermination_PlantVariantList_Row 
                                            plantVariantKey={plantVariant.variant_id} 
                                            plantVariantInfo={plantVariant} 
                                            seederChoice={seederChoice}
                                            updateSeederChoice={seederChoiceUpdated}
                                            seedingBegun={seedingBegun}
                                            variantTrackingOption={variantTrackingOption}
                                            totalNumberOfUnits={activeGrow.number_of_units}
                                            beginTracking={() => {beginTrackingClicked(plantVariant.data.id)}}
                                            columnSizes={columnSizes} 
                                            isReview={false}/>
                                    </>
                                })}
                                
                        </RowGroup>
                    </div>                    
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelTaskCallback} size={"Flex50"}/>
                {!seedingCompleted && <>
                    <Button status="Primary" content={"Start Seeding"} onClick={beginSeedingClicked} size={"Flex50"}/>
                </>} 
                {seedingCompleted && <>
                    <Button status="Primary" content={"Finished Seeding"} onClick={finishedSeedingClicked} size={"Flex50"} disabled={performingPlantSeedsCompleted}/>
                </>}
                
            </div>
        </div>
    )
    
}


export default PlantSeedsForGermination_SeedingListWalkthroughStep






export const PlantSeedsForGermination_PlantVariantList_Row = ({plantVariantKey, plantVariantInfo, totalNumberOfUnits, isReview, seederChoice, seedingBegun, variantTrackingOption, updateSeederChoice, beginTracking, isPlaceholder, columnSizes, columnSizesChanged}) => {
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  
    
    const allRaftTypes = useSelector(state => selectAllRaftTypes(state))
    const allPlantTypes = useSelector(state => selectAllPlantTypes(state))
    const allPlantVarities = useSelector(state => selectAllPlantVarieties(state))
    const allSeeds = useSelector((state) => selectAllGrowSeeds(state))

    const plantVariant = isPlaceholder ? null : plantVariantInfo.data

    let plantType = null
    let plantVariety = null
    let seed = null
    let seedlingRaftType = null
    if (plantVariant !== null)  {
        plantType = allPlantTypes.find((pT) => pT.id === plantVariant.plant_type_id)
        plantVariety = allPlantVarities.find((pV) => pV.id === plantVariant.plant_variety_id)
        seed = allSeeds.find((s) => s.id === plantVariant.seed_id)
        seedlingRaftType = allRaftTypes.find((rT) => rT.id === plantVariant.seedling_raft_type_id)
    }
    
    
    let numberOfUnits = 0
    let numberOfSeedlingBoards = 0
    if (plantVariant !== null)  {
        numberOfUnits = Math.ceil(totalNumberOfUnits * (plantVariant.ratio / 100))
        if (seedlingRaftType !== undefined) {
            numberOfSeedlingBoards = Math.ceil(numberOfUnits / seedlingRaftType.info.number_of_plant_sites)
        }
    }
  
    
    let secondaryHeaderContent = []
    if (!isMobile) {
        secondaryHeaderContent.push({
            key: "units", content: <>
                <div className="noselect">
                    <div className="Text-Alt14">
                        {isPlaceholder && <>
                            10000 units
                        </>}
                        {!isPlaceholder && <>
                            {numberOfUnits} unit{numberOfUnits > 1 && <>s</>}
                        </>}    
                    </div>
                    <div className="Text-S14">
                        {isPlaceholder && <>
                            2000 boards
                        </>}
                        {!isPlaceholder && <>
                            {numberOfSeedlingBoards} Board{numberOfSeedlingBoards > 1 && <>s</>}
                        </>}    
                    </div>
                </div>
            </>
        })

        secondaryHeaderContent.push({
            key: "seeder", content: <>
                <div className="FlexContent-H-5 noselect">
                    {isPlaceholder && <>
                        <Button content="Use Seeder" status={"Primary-Toggle"}/>
                        <Button content="Seed Manually" status={"Primary-Toggle"}/>
                    </>}
                    {!isPlaceholder && <>
                        {!seedingBegun && <>
                            <Button content="Use Seeder" status={"Primary-Toggle"} state={seederChoice === "use_seeder"} onClick={() => {updateSeederChoice(plantVariant.id, "use_seeder")}}/>
                            <Button content="Seed Manually" status={"Primary-Toggle"} state={seederChoice === "seed_manually"} onClick={() => {updateSeederChoice(plantVariant.id, "seed_manually")}}/>
                        </>}
                        {seedingBegun && <>
                            {variantTrackingOption === "tracked" && <>
                                <div className="Text-Aux14" style={{textAlign: "center", flex: 1}}>Tracked</div>
                            </>} 
                            {variantTrackingOption === "pending" && <>
                                <Button content="Not Tracked" status={"Neutral"} onClick={beginTracking} width={"Flex100"}/>
                            </>}                           
                            {variantTrackingOption === "skipped" && <>
                                <div className="Text-Aux14" style={{textAlign: "center", flex: 1}}>Skipped</div>
                            </>}
                        </>}
                    </>}
                </div>
            </>
        })
    }
  
    let title = ""
    if (isPlaceholder) {
        title = "Placeholder"
    }else {
        if (seed !== undefined) {
            title = seed.name
        }
    }
    

    return (<>
      <RowGroupRow
        key={isPlaceholder ? 0 : plantVariantKey}
        title={title}
        titleFontSize={14}
        cardBackgroundColor={"transparent"}
        cardBorderColor={"transparent"}
        showExpandedStatusContainerStyles={false}
        statusContainerPadding={0}
        minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
        hasFlexCell={true}
        showStatusIndicator={false}
        isPlaceholderRow={isPlaceholder}
        columnSizesChanged={columnSizesChanged ?? (() => {})}
        columnSizes={columnSizes}
        secondaryContent={secondaryHeaderContent}
        hasAdditionalFunctions={false}
        content={<>
            {(!isPlaceholder && isMobile) && <>
                <div className="FlexContent-15">
                    <div className="FlexContent" style={{gap: 3}}>
                        <div className="Text-Alt14">
                            {numberOfUnits} unit{numberOfUnits > 1 && <>s</>}
                        </div>
                        <div className="Text-S14">
                            {numberOfSeedlingBoards} Board{numberOfSeedlingBoards > 1 && <>s</>}
                        </div>
                    </div>
                    <div className="FlexContent-H-5">
                        <Button content="Use Seeder" status={"Primary-Toggle"} state={seederChoice === "use_seeder"} onClick={() => {updateSeederChoice(plantVariant.id, "use_seeder")}}/>
                        <Button content="Seed Manually" status={"Primary-Toggle"} state={seederChoice === "seed_manually"} onClick={() => {updateSeederChoice(plantVariant.id, "seed_manually")}}/>
                    </div>
                </div>
            </>}
        </>}
      />
    </>)
}