import './GrowZonesPage.scss';
import React from 'react'

import _ from 'underscore';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, getBladeZoneConfigurationMap, markGrowZoneForCleanse, selectAllBlades, selectAllBladeZoneConfigurationMaps, setBladeRuntimeProperty, setBladeZoneRuntimeProperty, MaintainBladeZoneStatus, MaintainBladeLiveData, selectBladeZoneDisplayNameByUID, selectAllBladeUIDs, getBladeByUID, MaintainBladeStatus, selectBladeUIDsForBladeZoneUID, selectBladeRuntimeInformationForBladeZoneUID, selectBladeDisplayNamesForBladeZoneUID, selectBladeZonePauseState, pauseZone, selectBladeControlDevicesForBladeZoneUID, selectBladeZoneTypeByUID, selectBladeZoneEventsByUID, resumeZone, selectBladeZoneRuntimeInformationByUID, selectBladeIdsForBladeZoneUID } from '../../redux/entities/service/Blade';
import GrowZoneDataAnalytics from './DataAnalytics';
import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import Button from '../../components/Button';
import { AirSetpoint, LightingSetpoint, LowerWaterSetpoint, Pause, Play, PlusAlt, RSSI, UpperWaterSetpoint, WaterSetpoint } from '../../assets/icons/Icons';
import { useMediaQuery } from 'react-responsive';
import DropDownInput from '../../components/input/DropDownInput';
import TextInput from '../../components/input/TextInput';
import { FormatDate, useRenderingTrace } from '../../helpers';








const GrowZonesPage_Zone = ({selectedFacility, zoneUID, onViewingTask}) => {

  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  /* React useEffects */
  const navigate = useNavigate()
  const dispatch = useDispatch()

  /* State */
  const [dataActive, SetDataActive] = React.useState(false)
  const [subtitle, SetSubTitle] = React.useState(<></>)
  const [zoneCleanState, SetZoneCleanState] = React.useState("unknown")

  /* Data */      
  const bladeZoneDisplayName = useSelector((state) => selectBladeZoneDisplayNameByUID(state, zoneUID), _.isEqual)
  const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeZoneEvents = useSelector((state) => selectBladeZoneEventsByUID(state, zoneUID), _.isEqual)
  const zonePauseState = useSelector((state) => selectBladeZonePauseState(state, zoneUID), _.isEqual)
  const isZonePaused = zonePauseState["paused"] !== undefined ? zonePauseState["paused"] : false
  const isZonePausing = zonePauseState["pausing"] !== undefined ? zonePauseState["pausing"] : false
  const isZoneResuming = zonePauseState["resuming"] !== undefined ? zonePauseState["resuming"] : false

  const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeRuntimeInformation = useSelector((state) => selectBladeRuntimeInformationForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeControlDevices = useSelector((state) => selectBladeControlDevicesForBladeZoneUID(state, zoneUID), _.isEqual)


  
  const isZoneOnline = true
  const [zoneIssues, SetZoneIssues] = React.useState([])
  React.useEffect(() => {
    let currentZoneIssues = []
    for (let [bladeType, bladeControlDevice] of Object.entries(bladeControlDevices)) {
      if (bladeControlDevice !== undefined && bladeControlDevice !== null)  {
        if (!bladeControlDevice.connected)  {
          if (bladeType === "nursery")  {
            currentZoneIssues.push({key: "blade_offline"})
          }else if (bladeType === "grow") {
            currentZoneIssues.push({key: "grow_blade_offline"})
          }else if (bladeType === "environment") {
            currentZoneIssues.push({key: "environment_blade_offline"})
          }
        }
      }
    }
    SetZoneIssues(currentZoneIssues)
  }, [bladeControlDevices])


  

  /* Interaction */
  const pauseZoneClicked = () => {
    pauseZone(zoneUID, bladeZoneType, dispatch)
  }

  const onManageZoneClicked = () => {
    navigate("/zonemanager/" + zoneUID);
  }

  let manualCleanseClicked = (bladeId, forReservoir) => {
    dispatch(markGrowZoneForCleanse({bladeId: bladeId, zoneUID: zoneUID, forReservoir: forReservoir, callback: (success, taskId) => {
      if (success)  {
        onViewingTask(taskId)
      }
    }}))
  }




  const [additionalFunctions, SetAdditionalFunctions] = React.useState([])

  React.useLayoutEffect(() => {
    let currentAdditionalFunctions = [
      {key: "rename", label: "Rename Zone"},
    ]
    if (bladeZoneType !== undefined)  {
      if (bladeZoneType === "nursery") {
        if (bladeIds["nursery"] !== undefined && bladeRuntimeInformation["nursery"] !== undefined) {
          let upperZoneCleanState = bladeRuntimeInformation["nursery"]["aux_a_reservoir_clean_state"]
          if (upperZoneCleanState === "clean") {
            currentAdditionalFunctions.push(
              {key: "upper_manual_cleanse", label: "Start Upper Zone Manual Cleanse", callback: () => {manualCleanseClicked(bladeIds["nursery"], "aux_a")}}
            )
          }else {
            currentAdditionalFunctions.push(
              {key: "upper_resume_cleanse", label: "Resume Upper Zone Manual Cleanse", callback: () => {}}
            )
          }
          
          let lowerZoneCleanState = bladeRuntimeInformation["nursery"]["aux_b_reservoir_clean_state"]
          if (lowerZoneCleanState === "clean") {
            currentAdditionalFunctions.push(
              {key: "lower_manual_cleanse", label: "Start Lower Zone Manual Cleanse", callback: () => {manualCleanseClicked(bladeIds["nursery"], "aux_b")}}
            )
          }else {
            currentAdditionalFunctions.push(
              {key: "lower_resume_cleanse", label: "Resume Lower Zone Manual Cleanse", callback: () => {}}
            )
          }
        }
        SetSubTitle(<><div className="FlexContent-H-5 Text-SoftTruncate">{<span className="GrowZonesStatus-VerticalRackGroup-Zone-Rack">{bladeDisplayNames["nursery"] !== undefined ? bladeDisplayNames["nursery"] : "N/A Rack"}</span>}</div></>)
      }else {
        if (bladeIds["grow"] !== undefined && bladeRuntimeInformation["grow"] !== undefined)  {
          let cleanState = bladeRuntimeInformation["grow"]["primary_reservoir_clean_state"]
          if (cleanState === "clean") {
            currentAdditionalFunctions.push(
              {key: "manual_cleanse", label: "Start Manual Cleanse", callback: () => {manualCleanseClicked(bladeIds["grow"], "primary")}}
            )
          }else {
            currentAdditionalFunctions.push(
              {key: "resume_cleanse", label: "Resume Manual Cleanse", callback: () => {}}
            )
          }
          SetZoneCleanState(cleanState)
        }else {
          SetZoneCleanState("unknown")
        }
        SetSubTitle(<><div className="FlexContent-H-5 Text-SoftTruncate">{<span className="GrowZonesStatus-VerticalRackGroup-Zone-Rack">{bladeDisplayNames["grow"] !== undefined ? bladeDisplayNames["grow"] : "N/A Rack"}</span>}<span style={{fontStyle:"normal"}}>|</span><span className="GrowZonesStatus-VerticalRackGroup-Zone-Rack">{bladeDisplayNames["environment"] !== undefined ? bladeDisplayNames["environment"] : "N/A Rack"}</span></div></>)
      }  
    }

    currentAdditionalFunctions.push(
      {key: "delete", label: "Delete", status: "Critical"}
    )
    if (isMobile) {
      currentAdditionalFunctions.splice(0, 0, 
        {key: "manage_zone", label: "Manage Zone", callback: () => {onManageZoneClicked()}}  
      )
      if (!isZonePaused) {
        currentAdditionalFunctions.splice(3, 0, 
          {key: "pause_zone", label: "Pause Zone", callback: () => {pauseZoneClicked()}}  
        )
      }
    }
    SetAdditionalFunctions(currentAdditionalFunctions)

  }, [bladeZoneType, bladeIds, bladeDisplayNames, bladeRuntimeInformation, isMobile])




  

  let secondaryHeaderContent = null
  if (!isMobile)  {
    secondaryHeaderContent = [
      {key: "manage_zone_button", content: 
        <div>
          <Button status="Hyperlink" content="Manage Zone" onClick={onManageZoneClicked}/>
        </div>
      },
      {key: "pause_state", content: <GrowZonesPage_Zone_InlineManageZonePause zoneUID={zoneUID} bladeUIDs={activeBladeUIDs}/>},
      {key: "rssi", content: <RSSI/>}
    ]
  }else {
    secondaryHeaderContent = <><RSSI/></>
  }

  let extendedHeaderContent = null
  if (isMobile && (isZoneResuming || isZonePaused || isZonePausing)) {
    extendedHeaderContent = {content: <>
      <GrowZonesPage_Zone_ExtendedManageZonePause zoneUID={zoneUID} bladeUIDs={activeBladeUIDs}/>
    </>}
  }else {

  }

  

  //useRenderingTrace("ZonesPage_Zone", [bladeZoneDisplayName, zoneIssues, dataActive, zoneUID, activeBladeUIDs, bladeRuntimeInformation, bladeIds, bladeControlDevices, bladeDisplayNames])
  //console.log("Rerender - Zones")

  

  
  return (
    <RowGroupRow
      key={zoneUID}
      title={bladeZoneDisplayName}
      hasFlexCell={true}
      subtitle={<div className="GrowZonesStatus-VerticalRackGroup-Zone-Racks">{subtitle}</div>}
      showStatusIndicator={true}
      status={isZoneOnline ? "Connected" : "Not Connected"}
      statusColor={isZoneOnline ? "#4DBE3B" : "#f00"}
      hasIssues={true}
      numberOfIssues={zoneIssues.length}
      isExpandable={true}
      hasAdditionalFunctions={true}
      secondaryContent={secondaryHeaderContent}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      onContentExpandChanged={(state) => SetDataActive(state)}
      content={<>
       {dataActive && <> 
        
        {/* Analytics Charting Area */}
        <div className="RowGroupRow-Content-Card">
          <div className="FlexContent-20 FlexContent-HFill">
            <GrowZoneDataAnalytics facilityId={selectedFacility} zoneUID={zoneUID} dataActive={dataActive} />
            
            <div className="GrowZonesStatus-EventsTable">
              <div className="GrowZonesStatus-EventsTable-TableHeader">
                <div className="GrowZonesStatus-EventsTable-TableHeader-Title">
                  Events
                </div>
                <div className="GrowZonesStatus-EventsTable-TableHeader-SearchContainer">
                  <TextInput value={""} placeHolder="Search Events" icon={<TextInput.SearchIcon/>} onChange={() => {}}/>
                </div>
                <div className="GrowZonesStatus-EventsTable-TableHeader-Filters">
                  <DropDownInput value="most_recent"
                    options={[
                      {value: "most_recent", label:"Most Recent"},
                      {value: "oldest", label:"Oldest"}
                    ]}/>
                  <DropDownInput value="most_recent"
                    options={[
                      {value: "most_recent", label:"Most Recent"},
                      {value: "oldest", label:"Oldest"}
                    ]}/>
                  <DropDownInput value="most_recent"
                    options={[
                      {value: "most_recent", label:"Most Recent"},
                      {value: "oldest", label:"Oldest"}
                    ]}/>
                  <DropDownInput value="most_recent"
                    options={[
                      {value: "most_recent", label:"Most Recent"},
                      {value: "oldest", label:"Oldest"}
                    ]}/>
                </div>
              </div>
              <div className="GrowZonesStatus-EventsTable-TableContent">
                {bladeZoneEvents.map((eventItem) => {
                  let eventOccurredOn = new Date(eventItem.occurred_on)
                  return (
                    <div className="GrowZonesStatus-EventsTable-EventItem" 
                      key={eventItem.id}>
                      <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails">
                        <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Date">
                          <div>
                            {FormatDate(eventOccurredOn, "MM/dd/yy")}
                          </div>
                          <div>
                            {FormatDate(eventOccurredOn, "hh:mm:ss")}
                          </div>
                        </div>
                        <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Info">
                          <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Info-Type">
                            {eventItem.type_display_name}
                          </div>
                          <div className="GrowZonesStatus-EventsTable-EventItem-EventDetails-Info-Content">
                            {eventItem.content}
                          </div>
                        </div>
                      </div>
                      View
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
       </>}
    </>}/>
  )
}


export default GrowZonesPage_Zone









const GrowZonesPage_Zone_InlineManageZonePause = ({zoneUID, bladeUIDs}) => {

  /* React useEffects */
  const dispatch = useDispatch()

  /* Data */
  const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeZoneRuntimeInformation = useSelector((state) => selectBladeZoneRuntimeInformationByUID(state, zoneUID), _.isEqual)
  const zonePauseState = useSelector((state) => selectBladeZonePauseState(state, zoneUID), _.isEqual)
  const isZonePaused = zonePauseState["paused"] !== undefined ? zonePauseState["paused"] : false
  const isZonePausing = zonePauseState["pausing"] !== undefined ? zonePauseState["pausing"] : false
  const isZoneResuming = zonePauseState["resuming"] !== undefined ? zonePauseState["resuming"] : false

  const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeRuntimeInformation = useSelector((state) => selectBladeRuntimeInformationForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeControlDevices = useSelector((state) => selectBladeControlDevicesForBladeZoneUID(state, zoneUID), _.isEqual)





  const pauseZoneClicked = () => {
    pauseZone(zoneUID, bladeZoneType, dispatch)
  }

  const resumeZoneClicked = () => {
    resumeZone(zoneUID, bladeZoneType, dispatch)
  }


  let lightingControlState = ""
  let liquidControlState = ""
  let airControlState = ""
  if (bladeZoneType !== undefined)  {
    if (bladeZoneType === "nursery")  {
      if (bladeRuntimeInformation["nursery"] !== undefined)  {
        lightingControlState = bladeRuntimeInformation["nursery"]["lights_control_status"]
      }
    }else {
      if (bladeRuntimeInformation["environment"] !== undefined)  {
        lightingControlState = bladeRuntimeInformation["environment"]["lights_control_status"]
      }
    }

    if (bladeZoneType !== "nursery")  {
      if (bladeRuntimeInformation["grow"] !== undefined)  {
        liquidControlState = bladeRuntimeInformation["grow"]["primary_liquid_control_status"]
      }
    }

    if (bladeZoneType === "nursery")  {
      if (bladeRuntimeInformation["nursery"] !== undefined)  {
        airControlState = bladeRuntimeInformation["nursery"]["air_control_status"]
      }
    }else {
      if (bladeRuntimeInformation["environment"] !== undefined)  {
        airControlState = bladeRuntimeInformation["environment"]["air_control_status"]
      }
    }
  
  
  }
  
  const toggleLightingPauseState = () => {
    let newState = lightingControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"], 
      properties: {"lights_control_status": newState},
      callback: (success) => {

      }
    }))
  }

  const toggleLiquidPauseState = () => {
    let newState = liquidControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds["grow"], 
      properties: {"primary_liquid_control_status": newState},
      callback: (success) => {

      }
    }))
  }
  
  const toggleAirPauseState = () => {
    let newState = airControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"], 
      properties: {"air_control_status": newState},
      callback: (success) => {

      }
    }))
  }


  return <>
    <div className="FlexContent-H-10">
      {(isZonePausing || isZoneResuming || isZonePaused) && <>
        {(isZonePaused || isZonePausing) && <>
          <Button 
            status={"Primary-Control-Toggle"}
            state={lightingControlState === "active"} 
            content={<LightingSetpoint fill={"#2E72D2"}/>} 
            height={17} size={"Custom"} 
            contentPadding={"5px 8px"} 
            onClick={toggleLightingPauseState} 
            disabled={isZoneResuming}/>

          {bladeZoneType === "nursery" && <>
            {(() => {
              if (bladeRuntimeInformation["nursery"] !== undefined)  {
                let upperLiquidState = bladeRuntimeInformation["nursery"]["aux_b_liquid_control_status"]
                let lowerLiquidState = bladeRuntimeInformation["nursery"]["aux_a_liquid_control_status"]
                const toggleUpperLiquidState = () => {
                  let newState = upperLiquidState === "active" ? "paused" : "active"
                  dispatch(setBladeRuntimeProperty({
                    bladeId: bladeIds["nursery"], 
                    properties: {"aux_b_liquid_control_status": newState},
                    callback: (success) => {
              
                    }}))
                }
                const toggleLowerLiquidState = () => {
                  let newState = upperLiquidState === "active" ? "paused" : "active"
                  dispatch(setBladeRuntimeProperty({
                    bladeId: bladeIds["nursery"], 
                    properties: {"aux_a_liquid_control_status": newState},
                    callback: (success) => {
              
                    }}))
                }
                return <>
                  <Button 
                    status={"Primary-Control-Toggle"} 
                    state={upperLiquidState === "active"} 
                    content={<UpperWaterSetpoint 
                    fill={"#2E72D2"}/>} 
                    height={17} 
                    size={"Custom"} 
                    contentPadding={"5px 8px"} 
                    onClick={toggleUpperLiquidState} 
                    disabled={isZoneResuming}/>
                  <Button 
                    status={"Primary-Control-Toggle"} 
                    state={lowerLiquidState === "active"} 
                    content={<LowerWaterSetpoint 
                    fill={"#2E72D2"}/>} 
                    height={17} 
                    size={"Custom"} 
                    contentPadding={"5px 8px"} 
                    onClick={toggleLowerLiquidState} 
                    disabled={isZoneResuming}/>
                </>
              }
            })()}
          </>}
          {bladeZoneType !== "nursery" && <>
            <Button 
              status={"Primary-Control-Toggle"} 
              state={liquidControlState === "active"} 
              content={<WaterSetpoint 
              fill={"#2E72D2"}/>} 
              height={17} 
              size={"Custom"} 
              contentPadding={"5px 8px"} 
              onClick={toggleLiquidPauseState} 
              disabled={isZoneResuming}/>
          </>}
          <Button 
            status={"Primary-Control-Toggle"}
            state={airControlState === "active"} 
            content={<AirSetpoint fill={"#2E72D2"}/>} 
            height={17} size={"Custom"} 
            contentPadding={"5px 8px"} 
            onClick={toggleAirPauseState} 
            disabled={isZoneResuming}/>
        </>}
        <div className="FlexContent-H-20 FlexContent-Center">
          {(() => {
            if (isZoneResuming || isZoneResuming)  {
              return <>
                <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Resuming</span>
                <Button content={"Add Time"} status={"Primary-Inverted"} size={"Small"} disabled={true}/>
              </>
            }else if (isZonePaused || isZonePausing) {
                let timeRemaining = 0
                if (bladeZoneRuntimeInformation !== undefined && bladeZoneRuntimeInformation["resume_controls_on"] !== undefined) {
                  timeRemaining = Math.floor(parseFloat(bladeZoneRuntimeInformation["resume_controls_on"]) - (new Date().getTime() / 1000))
                }
  
                if (timeRemaining < 0)  {
                  timeRemaining = 0
                }
                return <>
                  <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Paused {Math.floor(timeRemaining / 60)}:{Math.floor(timeRemaining % 60).toString().padStart(2, '0')}</span>
                  <Button status={"Primary-Inverted"} size={"Small"} content={"Add Time"}/>
                </>
            }
          })()}
        </div>
      </>}
      <div className="FlexContent-H-10">
        {(isZonePaused) && <>
          <Button status={"Primary-Inverted"} size={"Small"} content={<Play fill={"#2E72D2"}/>} onClick={resumeZoneClicked} disabled={isZoneResuming}/>
        </>}
        {(!isZonePaused) &&
          <Button status={"Small-IconAction"} size={"Small"} content={<Pause/>} onClick={pauseZoneClicked} disabled={isZonePausing}/>
        }
      </div>
    </div>
  </>
}

const GrowZonesPage_Zone_ExtendedManageZonePause = ({zoneUID, bladeUIDs}) => {

  /* React useEffects */
  const dispatch = useDispatch()

  /* Data */
  const bladeZoneType = useSelector((state) => selectBladeZoneTypeByUID(state, zoneUID), _.isEqual)
  const bladeZoneRuntimeInformation = useSelector((state) => selectBladeZoneRuntimeInformationByUID(state, zoneUID), _.isEqual)
  const zonePauseState = useSelector((state) => selectBladeZonePauseState(state, zoneUID), _.isEqual)
  const isZonePaused = zonePauseState["paused"] !== undefined ? zonePauseState["paused"] : false
  const isZonePausing = zonePauseState["pausing"] !== undefined ? zonePauseState["pausing"] : false
  const isZoneResuming = zonePauseState["resuming"] !== undefined ? zonePauseState["resuming"] : false

  const activeBladeUIDs = useSelector((state) => selectBladeUIDsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeIds = useSelector((state) => selectBladeIdsForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeDisplayNames = useSelector((state) => selectBladeDisplayNamesForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeRuntimeInformation = useSelector((state) => selectBladeRuntimeInformationForBladeZoneUID(state, zoneUID), _.isEqual)
  const bladeControlDevices = useSelector((state) => selectBladeControlDevicesForBladeZoneUID(state, zoneUID), _.isEqual)





  const pauseZoneClicked = () => {
    pauseZone(zoneUID, bladeZoneType, dispatch)
  }

  const resumeZoneClicked = () => {
    resumeZone(zoneUID, bladeZoneType, dispatch)
  }


  let lightingControlState = ""
  let liquidControlState = ""
  let airControlState = ""
  if (bladeZoneType !== undefined)  {
    if (bladeZoneType === "nursery")  {
      if (bladeRuntimeInformation["nursery"] !== undefined)  {
        lightingControlState = bladeRuntimeInformation["nursery"]["lights_control_status"]
      }
    }else {
      if (bladeRuntimeInformation["environment"] !== undefined)  {
        lightingControlState = bladeRuntimeInformation["environment"]["lights_control_status"]
      }
    }

    if (bladeZoneType !== "nursery")  {
      if (bladeRuntimeInformation["grow"] !== undefined)  {
        liquidControlState = bladeRuntimeInformation["grow"]["primary_liquid_control_status"]
      }
    }

    if (bladeZoneType === "nursery")  {
      if (bladeRuntimeInformation["nursery"] !== undefined)  {
        airControlState = bladeRuntimeInformation["nursery"]["air_control_status"]
      }
    }else {
      if (bladeRuntimeInformation["environment"] !== undefined)  {
        airControlState = bladeRuntimeInformation["environment"]["air_control_status"]
      }
    }
  
  
  }
  
  const toggleLightingPauseState = () => {
    let newState = lightingControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"], 
      properties: {"lights_control_status": newState},
      callback: (success) => {

      }
    }))
  }

  const toggleLiquidPauseState = () => {
    let newState = liquidControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds["grow"], 
      properties: {"primary_liquid_control_status": newState},
      callback: (success) => {

      }
    }))
  }
  
  const toggleAirPauseState = () => {
    let newState = airControlState === "active" ? "paused" : "active"
    dispatch(setBladeRuntimeProperty({
      bladeId: bladeIds[bladeZoneType === "nursery" ? "nursery" : "environment"], 
      properties: {"air_control_status": newState},
      callback: (success) => {

      }
    }))
  }

  return <>
    <div className="FlexContent-H-10 FlexContent-Center">
      {(isZonePaused || isZonePausing) && <>
        <Button status={"Primary-Inverted"} content={<LightingSetpoint fill={"#2E72D2"}/>} onClick={toggleLightingPauseState} disabled={isZoneResuming}/>
        <Button status={"Primary-Inverted"} content={<WaterSetpoint fill={"#2E72D2"}/>} onClick={toggleLiquidPauseState} disabled={isZoneResuming}/>
        <Button status={"Primary-Inverted"} content={<AirSetpoint fill={"#2E72D2"}/>} onClick={toggleAirPauseState} disabled={isZoneResuming}/>
      </>}
      {(() => {
        if (isZoneResuming)  {
          return <>
            <span className="GrowRacksStatus-VerticalRackGroup-Zone-PauseInfo-State">Resuming</span>
          </>
        }else if (isZonePaused || isZonePausing) {
          let timeRemaining = 0
          if (bladeZoneRuntimeInformation !== undefined && bladeZoneRuntimeInformation["resume_controls_on"] !== undefined) {
            timeRemaining = Math.floor(parseFloat(bladeZoneRuntimeInformation["resume_controls_on"]) - (new Date().getTime() / 1000))
          }

          if (timeRemaining < 0)  {
            timeRemaining = 0
          }
          return <>
            <Button status={"Primary-Inverted"} content={<><div>Paused {Math.floor(timeRemaining / 60)}:{Math.floor(timeRemaining % 60).toString().padStart(2, '0')}</div><PlusAlt/></>}/>
          </>
        }
      })()}
      {(isZonePaused) && <>
        <Button status={"Primary-Inverted"} content={<Play fill={"#2E72D2"}/>} onClick={resumeZoneClicked} disabled={isZoneResuming}/>
      </>}
      {(!isZonePaused) &&
        <Button status={"Primary-Inverted"} content={<Pause/>} onClick={pauseZoneClicked} disabled={isZonePausing}/>
      }
    </div>
  </>
}