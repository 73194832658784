
import './CreateGrowPlanWalkthrough.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import { SearchIcon } from '../../../../assets/icons/Icons';
import Button from '../../../../components/Button';
import TextInput from '../../../../components/input/TextInput';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import SelectSeeds from '../../../../model_components/SelectSeed';
import { useDispatch, useSelector } from 'react-redux';
import { getAllRaftTypes, selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import DropDownInput from '../../../../components/input/DropDownInput';
import { selectAllRecipes } from '../../../../redux/entities/Recipes';
import NumberInput from '../../../../components/input/NumberInput';
import { createGrowPlan } from '../../../../redux/entities/GrowPlans';
import { FormatDate } from '../../../../helpers';
import { selectAllGrowSeeds, selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';

import { CreateGrowPlant_PlantVariantList_Row } from './CreateGrowPlan_PlantVariantListWalkthroughStep';


const CreateGrowPlan_FinalReviewWalkthroughStep = ({
        selectedPlantType,
        selectedPlantVariety,
        selectedSeed,
        selectedSeedlingRaftType,
        selectedSeedingMethod,
        selectedSeedSelector,
        selectedSeedsPerPlug,
        selectedSeedlingSuccessRate,
        selectedSeedRatios,
        selectedRecipeId,
        selectedRecipeVersion,
        selectedGrowOutRaftType,
        selectedGrowOutRaftUsage,
        selectedGrowPlanName,
        selectedFinalProduct,
        completedGrowPlanCallback,
        transitionToPreviousStep, transitionToNextStep}) => {
            
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop
    
    const dispatch = useDispatch()


    const allRecipes = useSelector(state => selectAllRecipes(state))
    let activeRecipe = allRecipes.find((r) => r.id === selectedRecipeId && r.version === selectedRecipeVersion)
   
    const allRaftTypes = useSelector(state => selectAllRaftTypes(state))
    let growOutRaftType = allRaftTypes.find((rT) => rT.id === selectedGrowOutRaftType)
    const allPlantTypes = useSelector(state => selectAllPlantTypes(state))
    const allPlantVarities = useSelector(state => selectAllPlantVarieties(state))
    const allSeeds = useSelector((state) => selectAllGrowSeeds(state))



    const backClicked = () => {
        transitionToPreviousStep(["select_grow_plan_name", null])
    }

    const [isCompletingGrowPlan, SetIsCompletingGrowPlan] = React.useState(false)
    const completeClicked = () =>   {
        SetIsCompletingGrowPlan(true)
    }
    React.useEffect(() => {
        if (isCompletingGrowPlan)   {
            let finalizedGrowPlan = {
                name: selectedGrowPlanName, 
                plant_variants: Object.keys(selectedPlantType).map((index) => {
                    return {
                        plant_type_id: selectedPlantType[index],
                        plant_variety_id: selectedPlantVariety[index],
                        seed_id: selectedSeed[index],
                        seedling_raft_type_id: selectedSeedlingRaftType[index],
                        seeding_method: selectedSeedingMethod[index],
                        seed_selector_id: selectedSeedSelector[index],
                        seeds_per_plug: selectedSeedsPerPlug[index],
                        seedling_success_rate: selectedSeedlingSuccessRate[index],
                        ratio: selectedSeedRatios[index]
                    }
                }),
                recipe_id: selectedRecipeId,
                recipe_version: selectedRecipeVersion,
                grow_out_raft_type_id: selectedGrowOutRaftType,
                grow_out_raft_usage: selectedGrowOutRaftUsage,
                final_product_id: selectedFinalProduct,
            }
            //console.log(finalizedGrowPlan)
            dispatch(createGrowPlan({growPlanData: finalizedGrowPlan, callback: (success) => {
                if (success)    {
                    completedGrowPlanCallback()
                }
                SetIsCompletingGrowPlan(false)
            }}))
            SetIsCompletingGrowPlan(false)
        }
    }, [isCompletingGrowPlan])





    let plantVariantList = []
    for (let index of Object.keys(selectedPlantType))   {
        plantVariantList.push({
            data: {
                plant_type_id: selectedPlantType[index],
                plant_variety_id: selectedPlantVariety[index],
                seed_id: selectedSeed[index],
                seedling_raft_type_id: selectedSeedlingRaftType[index],
                seeding_method: selectedSeedingMethod[index],
                seed_selector_id: selectedSeedSelector[index],
                seeds_per_plug: selectedSeedsPerPlug[index],
                seedling_success_rate: selectedSeedlingSuccessRate[index],
                seeding_ratio: selectedSeedRatios[index]
            }
        })
    }

    
    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Plant</>, setPosition: (bounds, contentBounds) => {return {left: isMobile ? bounds.left - 30 : bounds.left}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
        seeds: {label: <>Seed</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
        ratio: {label: <>Ratio</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
        }
    }
 

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className={isMobile ? "FlexContent-10" : "FlexContent-15"}>
                        <div className="Linebreak-10 Text-S14">Review your grow plan</div>
                        {isMobile && <>
                            <div className="FlexContent-20">
                                <div className="FlexContent-20">
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Name</div>
                                        <div className="Text-S14">{selectedGrowPlanName}</div>
                                    </div>
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Recipe</div>
                                        <div className="FlexContent">{activeRecipe !== undefined && <>
                                            <div className="Text-S14">{activeRecipe.name}</div>
                                            <div className="Text-AltI14">v{activeRecipe.version} {FormatDate(new Date(activeRecipe.created_on), "YY/mm/dd")}</div>
                                        </>}</div>
                                    </div>
                                </div>                        
                                <div className="FlexContent-20">
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Grow Out Raft Type</div>
                                        <div className="Text-S14">{growOutRaftType !== undefined ? growOutRaftType.name : "Unknown Raft"}</div>
                                    </div>
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Grow Out Raft Usage</div>
                                        <div className="Text-S14">{selectedGrowOutRaftUsage}</div>
                                    </div>
                                </div>                        
                            </div>                        
                        </>}
                        {!isMobile && <>
                            <div className="FlexContent-H-40">
                                <div className="FlexContent-30">
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Name</div>
                                        <div className="Text-S14">{selectedGrowPlanName}</div>
                                    </div>
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Recipe</div>
                                        <div className="FlexContent">{activeRecipe !== undefined && <>
                                            <div className="Text-S14">{activeRecipe.name}</div>
                                            <div className="Text-AltI14">v{activeRecipe.version} {FormatDate(new Date(activeRecipe.created_on), "YY/mm/dd")}</div>
                                        </>}</div>
                                    </div>
                                </div>                        
                                <div className="FlexContent-30">
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Grow Out Raft Type</div>
                                        <div className="Text-S14">{growOutRaftType !== undefined ? growOutRaftType.name : "Unknown Raft"}</div>
                                    </div>
                                    <div className="FlexContent-H-10">
                                        <div className="Text-Medium-S14">Grow Out Raft Usage</div>
                                        <div className="Text-S14">{selectedGrowOutRaftUsage}</div>
                                    </div>
                                </div> 
                            </div>                                                
                        </>}
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            hasContainer={false}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}
                            hasSubGroups={false}>
                            
                                <CreateGrowPlant_PlantVariantList_Row isPlaceholder={true} isReview={true} columnSizesChanged={(columns) => {
                                    SetColumnSizes(columns)
                                }}/>
                                {plantVariantList.map((plantVariant) => {
                                    return <>
                                        <CreateGrowPlant_PlantVariantList_Row plantVariantKey={plantVariant.variant_id} plantVariantInfo={plantVariant} columnSizes={columnSizes} isReview={true}/>
                                    </>
                                })}
                                
                        </RowGroup>
                    </div>
                </div>        
            </div>                 
            <div className="Walkthrough-Card-FooterButtons">
                <Button status="Neutral" content={"Back"} onClick={backClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"}/>
                <Button content={"Complete"} onClick={completeClicked} size={isMobile ? "Medium" : "Large"} width={"Flex50"} disabled={isCompletingGrowPlan}/>
            </div>
        </div>
    )
    
}


export default CreateGrowPlan_FinalReviewWalkthroughStep
