import './HarvestTask.scss';
import React from 'react';

import { useMediaQuery } from 'react-responsive';
import Button from '../../../../components/Button';
import RowGroup from '../../../../components/RowGroup/RowGroup';
import RowGroupRow from '../../../../components/RowGroup/RowGroupRow';
import { useSelector } from 'react-redux';
import { selectAllRaftTypes } from '../../../../redux/entities/RaftTypes';
import { selectAllPlantTypes, selectAllPlantVarieties } from '../../../../redux/entities/PlantTypes';
import { selectAllBladeZones } from '../../../../redux/entities/service/Blade';


const HarvestTask_ZoneListWalkthroughStep = ({
        activeGrow,
        activeGrowPlan,
        beginHarvestingZoneConfirmedCallback,
        finishedHarvestingCallback,
        performingHarvestCompleted,
        cancelTaskCallback,
        transitionToPreviousStep, transitionToNextStep}) => {



    const isWideDesktop = useMediaQuery({ minWidth: 1800 });
    const isDesktop = useMediaQuery({ minWidth: 992 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    const isMobile = useMediaQuery({ maxWidth: 767 });



    const beginHarvesting = (zoneUID) => {
        beginHarvestingZoneConfirmedCallback({zoneUID: zoneUID})
        transitionToNextStep(["prepare_for_harvest", null])
    }
    const finishedHarvestingClicked = () => {
        finishedHarvestingCallback()
    }


    
    const [zoneHarvestList, SetZoneHarvestList] = React.useState([])
    React.useLayoutEffect(() => {
        SetZoneHarvestList(activeGrow !== undefined ? activeGrow.growout_location_entries ?? [] : [])
    }, [activeGrow])


       
    const [columnSizes, SetColumnSizes] = React.useState({})

    let columnHeaders = {status: {label: <>Zone Name</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
    if (!isMobile) {
        columnHeaders = {...columnHeaders, 
            units: {label: <>Units</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}},
            harvest_status: {label: <></>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width, alignItems:"center"}}}  
        }
    }
    

    return (
        <div className="Walkthrough-Card Walkthrough-CardFill">
            <div className="Walkthrough-Card-Header Walkthrough-Card-HeaderWithSeperator">
                <div className="Walkthrough-Card-HeaderContent">
                    Select harvest button to begin harvesting
                </div>
            </div>
            <div className={"Walkthrough-Card-Content-Wrapper"}>
                <div className={"Walkthrough-Card-Content"}>
                    <div className={isMobile ? "FlexContent-20" : "FlexContent-30"} style={{padding: isMobile ? "20px" : "40px 60px"}}>
                        
                        <RowGroup
                            hasGroupHeader={false}
                            hasSettings={false}
                            itemsScroll={true}
                            isExpandable={false}
                            hasColumnHeaders={true}
                            columnHeaders={columnHeaders}
                            columnSizes={columnSizes}
                            hasSubGroups={false}
                            hasContainer={false}
                            >
                            
                                <HarvestTask_ZoneList_Row isPlaceholder={true} columnSizesChanged={(columns) => {
                                    SetColumnSizes(columns)
                                }}/>
                                {zoneHarvestList.map((zone) => {
                                   
                                    return <>
                                        <HarvestTask_ZoneList_Row 
                                            zoneKey={zone.grow_out_uid} 
                                            zoneInfo={zone} 
                                            beginHarvestClicked={() => {beginHarvesting(zone.grow_out_uid)}}
                                            columnSizes={columnSizes} 
                                            isReview={false}/>
                                    </>
                                })}
                                
                        </RowGroup>
                    </div>                    
                </div>
            </div>
            <div className="Walkthrough-Card-FooterButtons">
                <Button content={"Cancel"} status={"Neutral"} onClick={cancelTaskCallback} size={"Flex50"}/>
                <Button status="Primary" content={"Finish Harvest"} onClick={finishedHarvestingClicked} size={"Flex50"} disabled={false}/>
                
            </div>
        </div>
    )
    
}


export default HarvestTask_ZoneListWalkthroughStep






export const HarvestTask_ZoneList_Row = ({zoneKey, zoneInfo, beginHarvestClicked, isReview, isPlaceholder, columnSizes, columnSizesChanged}) => {
    const isDesktop = useMediaQuery({ minWidth: 1079 });
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
    const isMobile = useMediaQuery({ maxWidth: 767 });
  

    const allBladeZones = useSelector((state) => selectAllBladeZones(state))
    let activeBladeZone = undefined
    if (!isPlaceholder) {
        activeBladeZone = allBladeZones.find((bZ) => bZ.uid === zoneInfo.grow_out_uid)
    }
    
    

    let numberOfUnits = 0
    let numberOfGrowOutRafts = 0
    
    let secondaryHeaderContent = []
    if (!isMobile) {
        secondaryHeaderContent.push({
            key: "units", content: <>
                <div className="noselect">
                    <div className="Text-Alt14">
                        {isPlaceholder && <>
                            10000 units
                        </>}
                        {!isPlaceholder && <>
                            {zoneInfo.number_of_units} unit{zoneInfo.number_of_units > 1 && <>s</>}
                        </>}    
                    </div>
                    <div className="Text-S14">
                        {isPlaceholder && <>
                            2000 boards
                        </>}
                        {!isPlaceholder && <>
                            {zoneInfo.number_of_grow_out_rafts}
                            &nbsp;
                            {(activeBladeZone !== undefined && activeBladeZone.zone_type === "grow_boards") && <>
                                Board
                            </>}
                            {(activeBladeZone !== undefined && activeBladeZone.zone_type === "berry_troughs") && <>
                                Root Bag
                            </>}
                            {zoneInfo.number_of_grow_out_rafts > 1 && <>s</>}
                        </>}    
                    </div>
                </div>
            </>
        })

        secondaryHeaderContent.push({
            key: "harvest_status", content: <>
                <div className="FlexContent-Center noselect">
                    {isPlaceholder && <>
                        <Button content={"Harvested"}/>
                    </>}
                    {!isPlaceholder && <>
                        {!zoneInfo.harvested && <>
                            <Button content={"Harvest"} status={"Primary"} onClick={beginHarvestClicked}/>
                        </>}
                        {zoneInfo.harvested && <>
                            <Button content={"Harvested"} status={"Neutral"} disabled={true}/>
                        </>}
                    </>}
                </div>
            </>
        })
    }
  
    let title = ""
    if (isPlaceholder) {
        title = "Placeholder Zone"
    }else {
        if (activeBladeZone !== undefined)  {
            title = activeBladeZone.display_name
        }else {
            title = "Unknown Zone"
        }
    }
    

    return (<>
      <RowGroupRow
        key={isPlaceholder ? 0 : zoneKey}
        title={title}
        titleFontSize={14}
        cardBackgroundColor={"transparent"}
        cardBorderColor={"transparent"}
        showExpandedStatusContainerStyles={false}
        statusContainerPadding={0}
        minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
        hasFlexCell={true}
        showStatusIndicator={false}
        isPlaceholderRow={isPlaceholder}
        columnSizesChanged={columnSizesChanged ?? (() => {})}
        columnSizes={columnSizes}
        secondaryContent={secondaryHeaderContent}
        hasAdditionalFunctions={false}
        content={<>
            {(!isPlaceholder && isMobile) && <>
                Test
            </>}
        </>}
      />
    </>)
}