import './GrowManagerPage.scss';
import variables from '../../globals.scss';
 
import React from 'react'

import { selectAllGrows, selectAllGrowGroups, getAllGrows, growUpdate, getGrowLiveData, scheduleNewGrow, scheduleNewGrowGroup, selectAllGrowIds, selectGrowLastLiveDataLoadedOn, useMaintainGrowLiveData} from '../../redux/entities/Grow'
import { getGrowPlanById, selectAllGrowPlans} from '../../redux/entities/GrowPlans'
import { selectAllRecipes, getRecipeById, GetCurrentGrowZoneCycle, GetCLIForCycle } from '../../redux/entities/Recipes';
import { useSelector, useDispatch } from 'react-redux'

import { animated, useSpring } from 'react-spring'
import {  useRenderingTrace } from '../../helpers'
import { Routes, Route, Navigate } from 'react-router-dom'
import {TabControl, TabControlTab} from '../../components/TabControl.js';
import {GanttChart} from '../../components/GanttChart';

import TextInput from '../../components/input/TextInput.js'
import DropDownInput from '../../components/input/DropDownInput.js'
import Button from '../../components/Button.js'
import {BsPinFill, BsPin, BsChevronDown, BsClockHistory, BsAlignStart, BsAlignEnd} from 'react-icons/bs'

import { FormatDate } from '../../helpers';

import 'react-circular-progressbar/dist/styles.css';
import { RecipeTimeline } from '../RecipesAndPlans/Recipes/RecipeTimeline';
import CircularGauge from '../../components/CircularGauge';

import RowGroupRow from '../../components/RowGroup/RowGroupRow';
import RowGroup from '../../components/RowGroup/RowGroup';
import { useMediaQuery } from 'react-responsive';
import ControlBar from '../../components/ControlBar';
import RowGroupSubGroup from '../../components/RowGroup/RowGroupSubGroup';
import { SearchIcon } from '../../assets/icons/Icons.js';
import ScheduleGrowPopup from './ScheduleGrowPlanWalkthroughSteps/ScheduleGrowPopup.js';
import GrowStatus_InfoContent from './GrowStatus/GrowStatus_InfoContent';
import GrowStatus_PerformanceContent from './GrowStatus/GrowStatus_PerformanceContent';
import GrowStatus_GrowProgress from './GrowStatus/GrowStatus_GrowProgress.js';
import { getAllBladeGroupsByFacilityId, getBladeZoneByUID, selectAllBladeZones } from '../../redux/entities/service/Blade.js';
import { selectAllFarmFacilities } from '../../redux/entities/Facilities.js';
import { IsSessionActive } from '../Account/Common.js';







const GrowStatusList = ({pageHeaderHelper, selectedFacility}) => { 
  
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const [scoreColors, setScoreColors] = React.useState({
    good: {min: 90, max:100, color: "rgba(90, 195, 100, 1)"},
    operational: {min: 75, max:90, color: "rgba(255, 220, 0, 1)"},
    critical: {min: 50, max:75, color: "rgba(255, 122, 44, 1)"},
    fail: {min: 0, max:50, color: "rgba(0, 255, 0, 1)"},
    neutral: {color: "rgba(120, 122, 12, 1)"},
  })

  const isSessionActive = useSelector(state => IsSessionActive(state))


  const allFarms = useSelector(selectAllFarmFacilities)
  let allGrows = useSelector(selectAllGrows)


  const activeGrowIds = useSelector(selectAllGrowIds)
  const lastLiveDataLoadedForGrows = useSelector((state) => selectGrowLastLiveDataLoadedOn(state, activeGrowIds))

  let growGroups = useSelector(selectAllGrowGroups)
  let allGrowPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)
  let haveGrowsForFacility = useSelector((state) => state.grows.haveGrowsForFacility)
  let loadingRecipeStatus = useSelector((state) => state.recipes.status)
  let lastRecipeRequestOn = useSelector((state) => state.recipes.lastRecipeRequestOn)
  const dispatch = useDispatch()

  
  useRenderingTrace("GrowManagerPage", [allGrows, activeGrowIds, allFarms, growGroups, allGrowPlans, recipes])

  React.useEffect( () =>  { 
    if (isSessionActive) {
      let farmsToLoadGrowsFor = []
      if (selectedFacility === "all") {
        for (let farm of allFarms)  {
          if (haveGrowsForFacility.indexOf(farm.id) === -1) {
            farmsToLoadGrowsFor.push(farm.id)
          }
        }
      }else {
        if (haveGrowsForFacility.indexOf(selectedFacility) === -1) {
          farmsToLoadGrowsFor.push(selectedFacility)
        }
      }
      if (farmsToLoadGrowsFor.length > 0) {
        dispatch(getAllGrows({facilityIds: farmsToLoadGrowsFor}))
      }
    }
  }, [isSessionActive, allFarms, selectedFacility, haveGrowsForFacility])


  const allBladeZones = useSelector(state => selectAllBladeZones(state))
  const allBladeGroups = useSelector(state => state.blade.groups)
  const loadedInitialBladeGroups = useSelector(state => state.blade.loadedInitialBladeGroups)
  const loadingGroupsStatus = useSelector(state => state.blade.loadingGroupsStatus)
  React.useEffect(() => {
    console.log(selectedFacility)
    let foundFacility = allFarms.find((f) => f.id === selectedFacility)
    if (foundFacility !== undefined)  {
      if (!loadedInitialBladeGroups && loadingGroupsStatus !== "pending") {
        dispatch(getAllBladeGroupsByFacilityId({facilityIds: [selectedFacility]}))
      }
    }
  }, [loadingGroupsStatus, loadedInitialBladeGroups, allFarms, selectedFacility])


  
  React.useEffect(() => {

    let bladeZonesToLoad = []
    for (let group of allBladeGroups) {
      if (group.facility_id === selectedFacility) {
        for (let zoneUID of group.zones)  {
          let foundBladeZone = allBladeZones.find((z) => z.uid === zoneUID)
          if (foundBladeZone === undefined) {
            bladeZonesToLoad.push(zoneUID)
          }
        }
      }
    }
    
    if (bladeZonesToLoad.length > 0) {
      dispatch(getBladeZoneByUID({UIDs: bladeZonesToLoad}))
    }

  }, [allBladeZones, allBladeGroups, selectedFacility])




  const [allGrowsList, SetGrowsList] = React.useState({})
  const [growGroupsList, SetGrowGroupsList] = React.useState({})
  const [growGroupsListProcessed, SetGrowGroupsListProcessed] = React.useState({})
  
  
  const [currentSearchInput, SetSearchInput] = React.useState("")



  

  const [schedulingGrow, SetSchedulingGrow] = React.useState(false)
  const closeSchedulingGrowPopup = () =>  {
    SetSchedulingGrow(false)
  }
  const schedulingGrowFinalized = (finalizedGrow) =>  {
    dispatch(scheduleNewGrow({grow: finalizedGrow}))
    SetSchedulingGrow(false)
  }
  const schedulingGrowGroupFinalized = (finalizedGroup, finalizedGrows) =>  {
    dispatch(scheduleNewGrowGroup({growGroup: finalizedGroup, allGrows: finalizedGrows}))
    SetSchedulingGrow(false)
  }




    
  /* Load grow live data */
  /*let loadingGrowLiveDataStatus = useSelector((state) => state.grows.loadingGrowLiveDataStatus)
  const [validateGrowLiveDataCallback, SetValidateGrowLiveDataCallback] = React.useState(null)
  const validateGrowLiveDataToLoad = () => {
    if (loadingGrowLiveDataStatus !== "pending") {
      SetValidateGrowLiveDataCallback(null)
      
      let growLiveDataToRequest = []
  
      for (let growId,  of Object.entries(lastLiveDataLoadedForGrows)) {
        if (grow.initialSave !== false) {
          if (grow.lastLiveDataUpdateOn + 1000 < new Date().getTime())  {
            growLiveDataToRequest.push(grow.id)
          }
        }      
      }
      
      if (growLiveDataToRequest.length > 0) {
        if (loadingGrowLiveDataStatus !== "pending")  {
          dispatch(getGrowLiveData({growIds: growLiveDataToRequest}))
        }
      }
        
    }else {
      if (validateGrowLiveDataCallback === null)    {
        SetValidateGrowLiveDataCallback(setTimeout(() => {validateGrowLiveDataToLoad()}, 500))
      }
    }
  }

  React.useEffect(() => {
    if (loadingGrowLiveDataStatus === "idle") {
      validateGrowLiveDataToLoad()
    }else if (loadingGrowLiveDataStatus === "fulfilled" || loadingGrowLiveDataStatus === "rejected") {
      if (validateGrowLiveDataCallback === null)    {
        SetValidateGrowLiveDataCallback(setTimeout(() => {validateGrowLiveDataToLoad()}, 500))
      }
    }
  }, [lastLiveDataLoadedForGrows, loadingGrowLiveDataStatus, validateGrowLiveDataCallback])
*/ 
  useMaintainGrowLiveData({growIds: activeGrowIds})


  /* Load grow plans */
  const growPlansLoadingStatus = useSelector((state) => state.growPlans.loadingGrowPlansStatus)
  const [validateGrowPlansPendingCallback, SetValidateGrowPlansPendingCallback] = React.useState(null)
  const validateGrowPlansToLoad = () => {
    if (growPlansLoadingStatus !== "pending") {
      SetValidateGrowPlansPendingCallback(null)
      
      let growPlanDataToRequest = []
      for (let grow of allGrows) {
        if (grow.initialSave !== false) {

          let foundGrowPlan = allGrowPlans.find((gP) => gP.id === grow.grow_plan_id)
          if (foundGrowPlan === undefined)  {
            if (growPlanDataToRequest.indexOf(grow.grow_plan_id) === -1)  {
              growPlanDataToRequest.push(grow.grow_plan_id)
            }
          }
    }      
      }

      if (growPlanDataToRequest.length > 0) {
        console.log("Loading grow plans", growPlanDataToRequest)
        dispatch(getGrowPlanById({growPlans: growPlanDataToRequest}))
      }
  }else {
      if (validateGrowPlansPendingCallback === null)    {
        SetValidateGrowPlansPendingCallback(setTimeout(() => {validateGrowPlansToLoad()}, 5000))
      }
    }
  }

  React.useEffect(() => {
      if (growPlansLoadingStatus === "idle") {
        validateGrowPlansToLoad()
      }else if (growPlansLoadingStatus === "fulfilled" || growPlansLoadingStatus === "rejected") {
        if (validateGrowPlansPendingCallback === null)    {
          SetValidateGrowPlansPendingCallback(setTimeout(() => {validateGrowPlansToLoad()}, 5000))
        }
      }
  }, [allGrows, allGrowPlans, growPlansLoadingStatus, validateGrowPlansPendingCallback])



  /* Load Recipes */
  const allRecipes = useSelector((state) => selectAllRecipes(state))
  const recipeLoadingStatus = useSelector((state) => state.recipes.status)
  const [validateRecipesPendingCallback, SetValidateRecipesPendingCallback] = React.useState(null)
  const validateRecipesToLoad = () => {
    if (recipeLoadingStatus !== "pending") {
      SetValidateRecipesPendingCallback(null)
        
      let recipeDataToRequest = []
 
      for (let grow of allGrows) {
        if (grow.initialSave !== false) {
          
          if (grow.recipe_id !== undefined && grow.recipe_id)  {
            let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
            if (foundRecipe === undefined)  {
              recipeDataToRequest.push({id: grow.recipe_id, version: grow.recipe_version})
            }
          }
        }      
      }


      
      if (recipeDataToRequest.length > 0) {
        dispatch(getRecipeById({recipes: recipeDataToRequest}))
      }
        
    }else {
      if (validateRecipesPendingCallback === null)    {
          SetValidateRecipesPendingCallback(setTimeout(() => {validateRecipesToLoad()}, 5000))
      }
    }
  }

  React.useEffect(() => {
    if (recipeLoadingStatus === "idle") {
      validateRecipesToLoad()
    }else if (recipeLoadingStatus === "fulfilled" || recipeLoadingStatus === "rejected") {
      if (validateRecipesPendingCallback === null)    {
          SetValidateRecipesPendingCallback(setTimeout(() => {validateRecipesToLoad()}, 5000))
      }
    }
  }, [allGrows, allRecipes, recipeLoadingStatus, validateRecipesPendingCallback])




  React.useEffect(() => {
    let currentGrowsList = {}

    let currentGrows = []
    

    if (currentSearchInput === "")  {
      currentGrows = [...allGrows]
    }else {
      currentGrows.push(...allGrows.filter((grow) => {
        if (grow.name.indexOf(currentSearchInput) !== -1 && currentGrows.indexOf(grow) === -1)  {
          return grow
        }
      }))
    }
    
    for (let grow of currentGrows) {
      let foundRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
      let foundGrowPlan = allGrowPlans.find((gP) => gP.id.toString() === grow.grow_plan_id)
      
      let growKey = "g-" + grow.id.toString()
      currentGrowsList[growKey] = {
        data: grow,
        isGroup: false,
      }


      if (foundRecipe !== undefined)  {
        currentGrowsList[growKey].duration = foundRecipe.expected_duration
        if (grow.started_on)  {
          currentGrowsList[growKey].startDate = new Date(grow.started_on).getTime()
          if (grow.completed) {
            currentGrowsList[growKey].endDate =  new Date(grow.finished_on).getTime()
          }else {
            currentGrowsList[growKey].endDate =  currentGrowsList[growKey].startDate + currentGrowsList[growKey].duration * 1000
          }
        }else {
          if (grow.scheduled_type === "nad" || grow.scheduled_type === "start_date") {
            currentGrowsList[growKey].startDate = new Date(grow.scheduled_for).getTime()
            currentGrowsList[growKey].endDate = currentGrowsList[growKey].startDate + (currentGrowsList[growKey].duration * 1000) 
          }else {
            currentGrowsList[growKey].endDate = new Date(grow.scheduled_for).getTime()
            currentGrowsList[growKey].startDate = currentGrowsList[growKey].endDate - (currentGrowsList[growKey].duration * 1000)
          }
        }
      }else {
        currentGrowsList[growKey].startDate = new Date(grow.scheduled_for).getTime()
      }
      currentGrowsList[growKey].key = currentGrowsList[growKey].startDate
      currentGrowsList[growKey].name = "#G" + grow.id
      
      switch (grow.stage)    {
        case "completed":
            currentGrowsList[growKey].statusColor = variables.neutral300
            currentGrowsList[growKey].status = "Neutral"
            break
          case "growing":
            currentGrowsList[growKey].statusColor = variables.surfaceSuccess
            currentGrowsList[growKey].status = "Success"
            break
        default:
          currentGrowsList[growKey].statusColor = variables.neutral400
          currentGrowsList[growKey].status = grow.stage
      }
      
    }
    
    SetGrowsList(currentGrowsList)
  }, [allGrows, growGroups, allGrowPlans, recipes, currentSearchInput])




  const columnHeaders = {
    status: {label: <>Name</>, setPosition: (bounds, contentBounds) => {return {left: bounds.left}}},
    timing: {label: <>Start/End</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
    timeline: {label: <>Timeline</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
    score: {label: <>Score</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.contentWidth, alignItems:"center"}}},
  }

  const [columnSizes, SetColumnSizes] = React.useState({})
  return (<>
    <ControlBar controls={<>
        <TextInput placeHolder="Search" icon={<SearchIcon/>}/>
        <DropDownInput status="AltNeutral" options={[{ value: 'all', label: 'All Categories' }]} defaultValue="all"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'all', label: 'All Years' }]} defaultValue="all"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'grouped_by_crop_name', label: 'Grouped By Crop Name' }]} defaultValue="grouped_by_crop_name"/>
        <DropDownInput status="AltNeutral" options={[{ value: 'name', label: 'Name' }]} defaultValue="name" prefix="Sort By"/>
      </>} secondaryControls={<>
        <Button content="Start Grow" onClick={() => {SetSchedulingGrow(true)}} disabled={selectedFacility==="all"}/>
      </>}
    />
    
    <RowGroup
      title={""}
      hasGroupHeader={false}
      hasSettings={false}
      isExpandable={false}
      itemsScroll={true}
      hasColumnHeaders={isMobile ? false : true}
      columnHeaders={columnHeaders}
      columnSizes={columnSizes}
      containerHasBorder={false}>
      <GrowStatusListRow isPlaceholder={true} columnSizesChanged={(columns) => {
        SetColumnSizes(columns)
      }}/>
      
      <RowGroupSubGroup title={<>Completed</>} expandedState={false} showRowSeperators={false} rows={
        Object.entries(allGrowsList).filter(([growKey, growInfo]) => growInfo.data.completed).map(([growKey, growInfo]) => {
          return <GrowStatusListRow key={growKey} growKey={growKey} growInfo={growInfo} columnSizes={columnSizes}/>
        })
      } numRowsDisplay="grows" emptyRowsDisplay="no grows found"/>
      <RowGroupSubGroup title={<>Active</>} expandedState={true} showRowSeperators={false} rows={
        Object.entries(allGrowsList).filter(([growKey, growInfo]) => !growInfo.data.completed).map(([growKey, growInfo]) => {
          
          return <GrowStatusListRow key={growKey} growKey={growKey} growInfo={growInfo} columnSizes={columnSizes}/>
        })
      } numRowsDisplay="grows" emptyRowsDisplay="no grows found"/>
      
    </RowGroup>

    {schedulingGrow && 
      <ScheduleGrowPopup selectedFacility={selectedFacility} closeCallback={closeSchedulingGrowPopup} growCompleteCallback={schedulingGrowFinalized} growGroupCompleteCallback={schedulingGrowGroupFinalized}/>
    }  
  
  </>)
}


export default GrowStatusList 










const GrowStatusListRow = ({growKey, growInfo, isPlaceholder, columnSizes, columnSizesChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const grow = isPlaceholder ? null : growInfo.data

  let allGrowPlans = useSelector(selectAllGrowPlans)
  let recipes = useSelector(selectAllRecipes)

  let activeRecipe = null
  let activeGrowPlan = undefined

  if (!isPlaceholder) {
    activeRecipe = recipes.find((r) => r.id === grow.recipe_id && r.version === grow.recipe_version)
    activeGrowPlan = allGrowPlans.find((gP) => gP.id === grow.grow_plan_id)
  }

  //console.log(activeGrowPlan)


  



  let additionalFunctions = [
    {key: "manage_lights", label: "Manage Lights"},
    {key: "rename", label: "Rename Rack"},
    {key: "move", label: "Move Rack"},
    {key: "change_sides", label: "Change Sides"},
    {key: "light_up", label: "Light Up Rack"},
    {key: "delete", label: "Delete", status: "Critical"},
  ]

  //console.log(growInfo)


  let currentProgress = 0
  if (!isPlaceholder) {
    if (grow.started_on && !grow.completed) {
      currentProgress = new Date().getTime() - new Date(grow.started_on).getTime()
    }
  }
  
  let timelineComponent = <>
    {activeRecipe && 
      <RecipeTimeline 
        recipe={activeRecipe}
        showProgress={!grow.completed}
        currentProgress={grow.started_on !== null ? new Date().getTime() - new Date(grow.started_on).getTime() : 0}
        showDuration={false}
        showDayTicks={false}
      />}
    {!activeRecipe && <>
      <div style={{textAlign:"center"}}>No Recipe Found</div>
    </>}
  </>

  let secondaryHeaderContent = []
  if (!isMobile) {
    secondaryHeaderContent.push({
      key: "timing", content: <>
      <div style={{fontSize:14}}>
        <div>{(!isPlaceholder ? FormatDate(new Date(growInfo.startDate), "MM/dd/yyyy") : "00/00/0000")}</div>
        <div>{(!isPlaceholder ? (growInfo.endDate ? FormatDate(new Date(growInfo.endDate), "MM/dd/yyyy") : "N/A") : "00/00/0000")}</div>
      </div>
    </>
    })
    secondaryHeaderContent.push({
      key: "timeline", content: timelineComponent, flex: true
    })
  }


  let havePercentage = false
  let percentage = 0
  if (!isPlaceholder) {
    percentage = grow.totalScore
  }


  secondaryHeaderContent.push(
    {key: "score", content: <>
      <div style={{width:"40px"}}>
        <CircularGauge value={parseInt(percentage)} text={havePercentage ? percentage : "N/A"} unit={""} valueDisplayFontSize={12}/>
      </div>
    </>}
  )
  

  let extendedHeaderContent = null
  if (isMobile) {
    extendedHeaderContent = {triggersExpandToggle: true, content: <div style={{display: "flex", alignSelf: "stretch", margin:10, flexDirection: "column"}}>
        {timelineComponent}
      </div>
    }
  }


  const [expandedContentPage, SetExpandedContentPage] = React.useState("grow_info")




  let tabSelected = (tab) => {
    SetExpandedContentPage(tab)
  }



  const allBladeZones = useSelector((state) => selectAllBladeZones(state))
  
  const [zoneOptions, SetZoneOptions] = React.useState([])
  const [selectedZoneOption, SetSelectedZoneOption] = React.useState(null)
  React.useLayoutEffect(() => {
    if (grow !== undefined && grow !== null) {
      let updatedZoneOptions = []

      if (grow.grow_out_zone_info)  {
        for (let zoneInfo of grow.grow_out_zone_info) {
          let foundZone = allBladeZones.find((bZ) => bZ.uid === zoneInfo.zone_uid)
          //console.log(allBladeZones)
          if (foundZone !== undefined)  {
            updatedZoneOptions.push({
              label: foundZone.display_name + (!zoneInfo.active ? " - Completed" : ""),
              value: foundZone.uid,
            })
          }
        }
      }

      if (selectedZoneOption === null && updatedZoneOptions.length > 0)  {
        SetSelectedZoneOption(updatedZoneOptions[0].value)
      }
      SetZoneOptions(updatedZoneOptions)
    }
  }, [grow, selectedZoneOption, allBladeZones])
  const zoneSelectionChanged = (zone) => {
    SetSelectedZoneOption(zone)
  }


  

  return (<>
  
    <RowGroupRow
      key={isPlaceholder ? 0 : grow.id}
      title={isPlaceholder ? "Placeholder" : activeGrowPlan !== undefined ? activeGrowPlan.name : "Unnamed Plan"}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      subtitle={<div className="GrowRacksStatus-VerticalRackGroup-Rack-Sides">{isPlaceholder ? "#G12512312" : "#G" + grow.id}</div>}
      showStatusIndicator={true}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      status={"Connected"}
      statusColor={"#4DBE3B"}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={true}
      customRowPadding={0}
      hasAdditionalFunctions={true}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      extendedHeaderContent={extendedHeaderContent}
      additionalFunctions={additionalFunctions}
      content={<>

        {(() => {
          if (isPlaceholder)  {
            return <></>
          }

          return (
            <div style={{display:"flex", flexDirection:"column", border:"1px solid #DDE3EB", gap:0, borderRadius: 4, backgroundColor:"#FFF"}}>
              <div style={{padding: "12px 20px 0px 20px"}}>
                {!isMobile && <>
                  <TabControl 
                    onTabSelected={tabSelected}
                    tabLocation={"flex"}
                    tabs={[
                      new TabControlTab("Grow Info", "grow_info", "", expandedContentPage === "grow_info"),
                      new TabControlTab("Performance", "performance", "", expandedContentPage === "performance"),
                      /*new TabControlTab("Metrics", "metrics", "", expandedContentPage === "metrics"),*/
                      new TabControlTab("Grow Progress", "grow_progress", "", expandedContentPage === "grow_progress"),
                      /*new TabControlTab("KPI's", "kpis", "", expandedContentPage === "kpis")*/
                    ]}
                  />
                </>}
                {isMobile && <>
                  <DropDownInput 
                    status="Neutral"
                    value={expandedContentPage}
                    options={[
                      {key: 'grow_info', label: 'Grow Info'},
                      {key: 'performance', label: 'Performance'},
                      /*{key: 'metrics', label: 'Metrics'},*/
                      {key: 'grow_progress', label: 'Grow Progress'},
                      /*{key: 'kpis', label: 'KPI\'s'}*/
                    ]} 
                    optionsKey="key"
                    onSelectionChange={(action) => {
                      SetExpandedContentPage(action)
                    }}/>
                </>}
              </div>

              {expandedContentPage === "grow_info" && <>
                <GrowStatus_InfoContent activeGrow={grow} activeGrowPlan={activeGrowPlan} activeRecipe={activeRecipe}/> 
              </>}

              {expandedContentPage === "performance" && <>
                <GrowStatus_PerformanceContent 
                  activeGrow={grow} 
                  activeGrowPlan={activeGrowPlan} 
                  activeRecipe={activeRecipe}
                  zoneOptions={zoneOptions}
                  selectedZoneUID={selectedZoneOption}
                  onZoneSelectionChanged={zoneSelectionChanged}/> 
              </>}
                     
              {expandedContentPage === "grow_progress" && <>
                <GrowStatus_GrowProgress 
                  activeGrow={grow} 
                  activeGrowPlan={activeGrowPlan} 
                  activeRecipe={activeRecipe}
                  allBladeZones={allBladeZones}
                  zoneOptions={zoneOptions}
                  selectedZoneUID={selectedZoneOption}
                  onZoneSelectionChanged={zoneSelectionChanged}/> 
              </>}       
            </div>
            )
        })()}
      </>}
    />
</>)
}
GrowStatusListRow.defaultProps = {
  isPlaceholder: false,
}

