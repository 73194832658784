
import './SelectSeed.scss';
import React from 'react'
import { useMediaQuery } from 'react-responsive';
import RowGroup from '../components/RowGroup/RowGroup';
import RowGroupRow from '../components/RowGroup/RowGroupRow';
import { useDispatch, useSelector } from 'react-redux';
import { getGrowSeedsByPlantVarietyId, selectAllGrowSeeds, selectAllPlantVarieties } from '../redux/entities/PlantTypes';



const SelectSeeds = ({selectedPlantVarietyId, selectedSeed, onSeedSelection}) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isShortDisplay = useMediaQuery({maxHeight: 800}) && isDesktop


  const allPlantVarieties = useSelector(selectAllPlantVarieties)
  const allSeeds = useSelector((state) => selectAllGrowSeeds(state))
  const activePlantVariety = allPlantVarieties.find((pT) => pT.id === selectedPlantVarietyId)


  const dispatch = useDispatch()
  React.useEffect(() => {
    if (!activePlantVariety.loaded_grow_seeds)  {
      dispatch(getGrowSeedsByPlantVarietyId({plantVarietyId: activePlantVariety.id}))
    }
  }, [activePlantVariety])

  const [plantVaritiesList, SetSeedsList] = React.useState({})
  const [currentSearchInput, SetSearchInput] = React.useState("")
  const [currentSelectedSeed, SetSelectedSeed] = React.useState(undefined)
  React.useEffect(() => {
    if (currentSelectedSeed !== selectedSeed) {
      SetSelectedSeed(selectedSeed)
    }
  }, [selectedSeed])
  

  React.useEffect(() => {
    let currentSeedsList = {}

    let currentSeeds = []
    
    let availableSeeds = []
    if (selectedPlantVarietyId)  {
      availableSeeds = allSeeds.filter((seed) => seed.plant_variety_id === selectedPlantVarietyId)
    }else {
      availableSeeds = [...allSeeds]
    }

    if (currentSearchInput === "")  {
      currentSeeds = [...availableSeeds]
    }else {
      currentSeeds.push(...availableSeeds.filter((seed) => {
        if (seed.name.toLowerCase().indexOf(currentSearchInput.toLowerCase()) !== -1 && currentSeeds.indexOf(seed) === -1)  {
          return seed
        }
      }))
    }
    
    
    for (let seed of currentSeeds) {
      currentSeedsList[seed.id] = {
        data: seed,
      }
    }


    SetSeedsList(currentSeedsList)
  }, [allSeeds, currentSearchInput, currentSelectedSeed])




  const onSearchInput = (value) => {
    SetSearchInput(value)
  }


  const handleSeedSelectedStateChanged = (seedId, state) => {
    if (selectedSeed === seedId)    {
        if (state)  {
            SetSelectedSeed(seedId)
            if (onSeedSelection !== undefined) {
              onSeedSelection(seedId)
            }
        }else {
            SetSelectedSeed(null)
            if (onSeedSelection !== undefined) {
              onSeedSelection(null)
            }
        }
    }else if (state) {
        SetSelectedSeed(seedId)
        if (onSeedSelection !== undefined) {
          onSeedSelection(seedId)
        }
    }
}


  const [columnSizes, SetColumnSizes] = React.useState({})

  let columnHeaders = {status: {label: <>Varient Name</>, setPosition: (bounds, contentWidth) => {return {left: bounds.left, width: bounds.width}}}}
  if (!isMobile) {
      columnHeaders = {...columnHeaders, 
      }
  }

  return (
    <>
      <div id="SelectSeeds">
      <RowGroup
          hasGroupHeader={false}
          hasSettings={false}
          hasContainer={false}
          itemsScroll={true}
          isExpandable={false}
          hasColumnHeaders={true}
          columnHeaders={columnHeaders}
          columnSizes={columnSizes}
          hasSubGroups={true}>

          <SelectSeeds_SeedRow isPlaceholder={true} columnSizesChanged={(columns) => {
              SetColumnSizes(columns)
          }}/>

          {Object.entries(plantVaritiesList).map(([seedIdAsString, seedInfo]) => {
              let seedId = parseInt(seedIdAsString)
              return (
                <SelectSeeds_SeedRow
                  key={seedId}
                  seedKey={seedId} 
                  seedInfo={seedInfo} 
                  isSelected={selectedSeed === seedId}
                  onSelectedChanged={(state) => {handleSeedSelectedStateChanged(seedId, state)}}
                  columnSizes={columnSizes}/>
              ) 
            })}
      </RowGroup>
      </div>
    </>
  )
} 

export default SelectSeeds

SelectSeeds.defaultProps = {
  selectedPlantVariety: null,
}


const SelectSeeds_SeedRow = ({seedKey, seedInfo, isPlaceholder, isSelected, columnSizes, columnSizesChanged, onSelectedChanged}) => {
  const isDesktop = useMediaQuery({ minWidth: 1079 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1079 });
  const isMobile = useMediaQuery({ maxWidth: 767 });


  const seed = isPlaceholder ? null : seedInfo.data

  let secondaryHeaderContent = []

  let title = isPlaceholder ? <>My Amazing Lettuce is the best</> : seed.name
  
  //let subtitle = (isPlaceholder ? <div className="">#1021501250120</div> : <div className="">#GP{growPlan.uid}</div>)

  return (<>
    <RowGroupRow
      key={isPlaceholder ? 0 : seedKey}
      title={title}
      titleFontSize={14}
      cardBackgroundColor={"transparent"}
      cardBorderColor={"transparent"}
      showRowSeperator={true}
      showExpandedStatusContainerStyles={false}
      statusContainerPadding={0}
      statusContainerClicked={() => {}}
      minimumTitleWidth={!isMobile ? "20ch" : "20ch"}
      hasFlexCell={true}
      showStatusIndicator={false}
      isPlaceholderRow={isPlaceholder}
      columnSizesChanged={columnSizesChanged ?? (() => {})}
      columnSizes={columnSizes}
      secondaryContent={secondaryHeaderContent}
      secondaryContentTriggersExpandToggle={true}
      hasIssues={true}
      numberOfIssues={0}
      isExpandable={false}
      isSelectable={true}
      onSelectedChanged={(state) => {onSelectedChanged(state); isSelected = state}}
      selectedState={isSelected}
      hasAdditionalFunctions={false}
      content={<></>}/>
  </>)
}